import {
  Box,
  Button,
  Container,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { login } from "../../data/Controller";
import { useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import LogoLight from "../../assets/images/msb-control-logo-light.svg";
import LogoDark from "../../assets/images/msb-control-logo-dark.svg";
import Alert from "@mui/material/Alert";
import { useState } from "react";

const Login = ({ credentials, setCredentials }) => {
  const theme = useTheme();
  const [errorMsg, setErrorMsg] = useState("");
  const colors = tokens(theme.palette.mode);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    const cred = await login(values);
    if (cred.error) {
      setErrorMsg("There was an error while authenticating");
    } else {
      setCredentials(cred);
    }
  };

  useEffect(() => {
    const { id, role, email } = credentials;
    if (id && role && email) {
      const state = signIn({
        expiresIn: 3600,
        authState: credentials,
      });
      if (state) {
        setErrorMsg("");
        navigate("/");
        return () => window.location.reload();
      }
    }
  }, [credentials]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="sm" style={{ height: "100%" }}>
      <Box
        alignContent="center"
        alignItems="center"
        flex
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                gap="30px"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                }}
              >
                <img
                  src={theme.palette.mode === "light" ? LogoDark : LogoLight}
                  width={140}
                  style={{ margin: "20px  auto 40px", display: "block" }}
                  alt="MSB-Control"
                />
                {errorMsg && (
                  <Alert severity="error" style={{ marginBottom: "20px" }}>
                    {errorMsg}
                  </Alert>
                )}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="User"
                  onBlur={handleBlur}
                  color="primary"
                  onChange={handleChange}
                  value={values.user}
                  name="user"
                  InputLabelProps={{
                    style: { color: colors.greenAccent[300] },
                  }}
                  error={!!touched.user && !!errors.user}
                  helperText={touched.user && errors.user}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  InputLabelProps={{
                    style: { color: colors.greenAccent[300] },
                  }}
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Login
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

const checkoutSchema = yup.object().shape({
  user: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValues = {
  user: "",
  password: "",
};

export default Login;
