import { Alert, Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useSignIn } from "react-auth-kit";
import { login, userAuthentication } from "../../data/Controller";

const CrossSiteAutnetication = ({ credentials, setCredentials }) => {
    const { id, role, email } = useParams();
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (email) {
            let decodedEmail = decodeBase64(email);
            const updatedCredentials = { email: decodedEmail };
            handleUserAuthentication(updatedCredentials);
        } else {
            alert("Missing params");
        }
    }, []);

    const handleUserAuthentication = async (credentials) => {
        const response = await userAuthentication(credentials);

        if (response && response.status && response.data) {
            let { agent_id, email, role, agency } = response.data;
            let cred = { agent_id, email, role, agency }

            setCredentials(cred);
            await userAuthorization(cred);
        } else {
            setErrorMsg(response?.message);
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }
    };

    const userAuthorization = (updatedCredentials) => {
        const state = signIn({
            expiresIn: 3600,
            authState: updatedCredentials,
        });

        if (state) {
            setErrorMsg("");
            setCredentials(updatedCredentials);
            navigate("/");
            return () => window.location.reload();
        } else {
            setCredentials({});
            setErrorMsg("Authentication Failed: Invalid Parameters");

            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }
    };

    const decodeBase64 = (base64Value) => {
        try {
            const decoded = atob(base64Value);
            return decoded;
        } catch (error) {
            alert("Invalid Base64 string!");
        }
    };

    return (
        <>
            <Container maxWidth="sm" style={{ height: "100%" }}>
                <Box m="20px">
                    {errorMsg && (
                        <Alert severity="error" style={{ marginBottom: "20px" }}>
                            {errorMsg}
                        </Alert>
                    )}

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <img
                            src="./static/media/msb-control-logo-light.5b9565e3525e25cad2646787c609ab82.svg"
                            alt="MSB Controls"
                            style={{
                                margin: "20px auto 40px",
                                display: "block",
                                maxWidth: "140px",
                                maxHeight: "105px",
                            }}
                        />
                    </Box>
                    <Box alignItems="center" textAlign={"center"}>
                        <h1>Authenticating...</h1>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default CrossSiteAutnetication;
