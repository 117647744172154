import {
  getTransactionsStatus,
  ConvertToNumber,
  formatCurrency,
} from "../../utils";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import WifiFindIcon from "@mui/icons-material/WifiFind";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Header from "../../components/Header";
// import Map from "../../components/Map/Map";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { useContext } from "react";
import { Context } from "../../context";
import DonutChart from "../../components/DonutChart";
import { ThresholdDashboardTable } from "../../components/ThresholdDashboardTable/ThresholdDashboardTable";
import { CountriesDashboardTable } from "../../components/CountriesDashboardTable/CountriesDashboardTable";
import { LicenseDashboardTable } from "../../components/LicenseDashboardTable/LicenseDashboardTable";
import { SendersDashboardTable } from "../../components/SendersDashboardTable/SendersDashboardTable";
import { ReceiversDashboardTable } from "../../components/ReceiversDashboardTable/ReceiversDashboardTable";

const Help = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader, periodFrom, setPeriodFrom, periodTo, setPeriodTo] =
    useContext(Context);

  const paidTransactions = getTransactionsStatus(props.transactions, "Paid");
  const cancelledTransactions = getTransactionsStatus(
    props.transactions,
    "Cancelled"
  );
  const rawTransactions = props.rawTransactions;
  const transactionsByCountry = props.transactionsByCountry;
  const transactionsByCountryMax = props.transactionsByCountryMax;
  const transactionsByLicense = props.transactionsByLicense;
  const transactionsByLicenseMax = props.transactionsByLicenseMax;

  const totalAmountSent = formatCurrency(
    props.transactions.reduce(
      (acc, cur) => ConvertToNumber(cur.amount) + acc,
      0
    )
  );
  // let aggregationSpan = 12;
  // if (transactionsByCountry.length < 2) aggregationSpan -= 6;
  // if (transactionsByLicense.length < 2) aggregationSpan -= 6;

  // if (transactionsByCountry.length > 1 && transactionsByLicense.length > 1) {
  //   aggregationSpan = 12;
  // } else if (
  //   transactionsByCountry.length === 1 &&
  //   transactionsByLicense.length === 1
  // ) {
  //   aggregationSpan = 12;
  // } else {
  //   aggregationSpan = 6;
  // }

  // return props.transactions.length > 0 ? (
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="DASHBOARD"
          subtitle="Here, you will find a high-level overview of your business's money remittance transactional profile. This includes transactions conducted via all LMTRs principals for which you act as an agent on their behalf."
        />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: 2,
            }}
            href="/assets/template.xls"
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Template
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      {props.transactions.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* 1. TOTAL AGGREGATION*/}
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={props.findings.length.toLocaleString("en-US")}
              subtitle="Total aggregations"
              // total={totalAmountAggregated}
              to="/analysis"
              progress={String(
                parseFloat(
                  props.findings.length / paidTransactions.length
                ).toFixed(2)
              )}
              increase={
                parseFloat(
                  (props.findings.length / paidTransactions.length) * 100
                )
                  .toFixed(2)
                  .toLocaleString(undefined, { style: "percent" }) + "%"
              }
              icon={
                <WifiFindIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 3. TOTAL COUNT AND DOLLAR VALUE*/}
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={props.transactions.length.toLocaleString("en-US")}
              subtitle="Total count and Dollar value"
              total={totalAmountSent}
              to="/transactions"
              // progress="0.50"
              // increase={"5%"}
              icon={
                <MultipleStopIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 3. CANCELLED TRANSACTIONS*/}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={cancelledTransactions.length.toLocaleString("en-US")}
              subtitle="Cancelled transactions"
              progress={String(
                parseFloat(
                  cancelledTransactions.length / props.transactions.length
                ).toFixed(2)
              )}
              increase={
                String(
                  parseFloat(
                    cancelledTransactions.length / props.transactions.length
                  ).toFixed(2) * 100
                ) + "%"
              }
              icon={
                <ReceiptIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 4. PAID TRANSACTIONS*/}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={paidTransactions.length.toLocaleString("en-US")}
              subtitle="Paid transactions"
              progress={String(
                parseFloat(
                  paidTransactions.length / props.transactions.length
                ).toFixed(2)
              )}
              increase={
                String(
                  parseFloat(
                    paidTransactions.length / props.transactions.length
                  ).toFixed(2) * 100
                ) + "%"
              }
              icon={
                <ReceiptIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Help;
