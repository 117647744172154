import {
  getTransactionsStatus,
  normalizeDataSet,
  calculatePeriod,
  capitalize,
} from "../utils";

import { insertAggregation } from "../data/Controller";
import { Dataset } from "@mui/icons-material";

const calculateAggregation = (auth, dataSet, limit) => {
  const transactionList = {};
  const analisys = {};
  const aggregationList = [];
  let senderSanitized = "";

  // Calculate aggregation 3k
  for (let transaction of dataSet) {
    const {
      id,
      sender,
      receiver,
      date,
      amount,
      country,
      license,
      agency,
      batch_date,
    } = transaction;

    //don't count the transaction if the amount is greater than the limit
    if (amount >= limit) continue;

    senderSanitized = sender.replace(/\s/g, "").toLowerCase();
    // console.log(senderSanitized);

    if (!transactionList[senderSanitized + "," + date]) {
      transactionList[senderSanitized + "," + date] = {
        aggregation_id: String(senderSanitized + "," + date + amount)
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase(),
        aggregation: 0,
        transactionAvg: 0,
        transactions: [],
        recipients: new Set(),
        batch_date,
      };
    }

    // Aggregating amounts

    transactionList[senderSanitized + "," + date]["aggregation"] += amount;
    transactionList[senderSanitized + "," + date]["sender"] = sender;

    // Calculating Avg
    if (
      transactionList[senderSanitized + "," + date]["transactions"].length >
      0 &&
      transactionList[senderSanitized + "," + date]["aggregation"] > 0
    ) {
      const aggregation =
        transactionList[senderSanitized + "," + date]["aggregation"];

      const len =
        transactionList[senderSanitized + "," + date]["transactions"].length;

      transactionList[senderSanitized + "," + date]["transactionAvg"] =
        aggregation / len;
    }

    // Maintaining the list of transactions
    transactionList[senderSanitized + "," + date]["transactions"].push({
      id,
      sender,
      receiver,
      date,
      amount,
      country,
      agency,
      license,
      batch_date,
    });

    //Adding unique recipients

    transactionList[senderSanitized + "," + date]["recipients"].add(receiver);
  }

  for (let key in transactionList) {
    if (
      transactionList[key]["aggregation"] >= limit &&
      transactionList[key]["transactions"].length > 1
    ) {
      const customerName = transactionList[key]["sender"];

      //Converting sets into Array
      const transactions = transactionList[key];
      transactions.recipients = Array.from(transactions.recipients);

      //Extrating list of agencies
      const agencySet = new Set();
      for (let t of transactionList[key].transactions) {
        agencySet.add(capitalize(t.agency));
      }
      //Extrating list of licenses
      const licenseSet = new Set();
      for (let t of transactionList[key].transactions) {
        licenseSet.add(t.license);
      }

      if (!analisys[customerName]) analisys[customerName] = [];
      analisys[customerName].push(transactionList[key]);

      aggregationList.push({
        _id: auth().agent_id + transactionList[key].aggregation_id,
        date: transactions.transactions[0]["date"],
        agent_id: auth().agent_id,
        ma: agencySet.size > 1 ? true : false,
        ml: licenseSet.size > 1 ? true : false,
        agency: Array.from(agencySet.values()),
        name: customerName,
        transactions: transactions,
        batch_date: transactionList[key]["batch_date"],
      });
      // index++;
    }
  }

  return aggregationList;
};

export const transactionAnalyzer = async (
  auth,
  rawTransactions,
  setAggregation,
  setPeriodFrom,
  setPeriodTo,
  setLoader
) => {
  setLoader(true);
  // Considering only paid transactions
  const dataSet = normalizeDataSet(
    getTransactionsStatus(rawTransactions, "Paid")
  );

  //Calculating period
  const { from, to } = calculatePeriod(dataSet);
  setPeriodFrom(String(from.toISOString().substring(0, 10)));
  setPeriodTo(String(to.toISOString().substring(0, 10)));

  // Calculate aggregation 10k
  const kyc = calculateAggregation(auth, dataSet, 3000);

  // Calculate aggregation 10k
  const ctr = calculateAggregation(auth, dataSet, 10000);

  // merging all records
  const allTransactions = [...kyc, ...ctr];

  await insertAggregation(allTransactions, auth().agent_id);
  setAggregation(allTransactions);
  setLoader(false);
};
