/**
 * Groups and aggregates transaction data by a specified key, returning an array of grouped data objects.
 *
 * @param {Object[]} rawTransactions - An array of raw transaction objects.
 * @param {string} k - The key to group the transactions by.
 * @returns {Object[]} An array of grouped transaction data objects.
 *
 */
export const groupAmountsBy = (rawTransactions, k) => {
  // Define the data you want to display in the chart
  const data = [];

  // Group by countries
  const transactionsBy = [];

  for (let transaction of rawTransactions) {
    let key = null;
    if (!transaction[k] || transaction[k] === "") {
      key = "Others";
    } else {
      key = transaction[k]
        .toLowerCase()
        .replace(/\s{2,}/g, " ")
        .trimStart()
        .trimEnd();
    }

    if (!transactionsBy[key]) {
      transactionsBy[key] = { amount: 0, frequency: 0, id: 0 };
    }
    transactionsBy[key]["name"] = transaction[k] || "Others";
    transactionsBy[key]["id"] = transaction.id;
    transactionsBy[key]["amount"] += transaction.amount;
    transactionsBy[key]["frequency"]++;
  }

  for (let key in transactionsBy) {
    const t = transactionsBy[key];

    data.push({
      id: t.id,
      name: t.name,
      amount: t.amount,
      frequency: t.frequency,
      avg: (t.amount / t.frequency).toFixed(2),
    });
  }

  return data;
};
