import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { calculateTransactionThresholds } from "../../utils";
import Header from "../../components/Header";

export const ThresholdDashboardTable = ({ rawTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tableData = calculateTransactionThresholds(rawTransactions);
  const columns = [
    {
      field: "category",
      headerName: "Threshold",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "frequency",
      headerName: "Number of Trx",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{params.row.frequency.toLocaleString("en-US")}</Typography>
      ),
    },
    {
      field: "avg",
      headerName: "Period percentage",
      minWidth: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Typography>{params.row.avg.toFixed(0) + "%"}</Typography>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title=""
        subtitle="This section provides detailed information on the concentration of risk associated with transaction dollar values, including multiple ranges and overall percentages. This will help you adopt a risk-based approach to assess the control environment of your AML and BSA compliance program."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      <Box
        m="20px 00"
        height="405px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};
