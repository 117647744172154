import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { formatCurrency, getTop10 } from "../../utils";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

export const ReceiversDashboardTable = ({ rawTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tableData = getTop10(rawTransactions, "receiver");
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Beneficiary",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Total Amount Received",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{formatCurrency(params.row.amount)}</Typography>
      ),
    },
    {
      field: "frequency",
      headerName: "Number of Trx",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
    },
    {
      field: "avg",
      headerName: "Period Avg",
      minWidth: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Typography>{formatCurrency(Number(params.row.avg))}</Typography>
      ),
    },
    {
      field: "partner",
      headerName: "Number of Senders",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
    },
  ];

  const handleRowClick = (params) => {
    navigate("/transactions", {
      state: { selection: params.row.name, field: "receiver" },
    });
  };

  return (
    <Box m="20px">
      <Header
        title=""
        subtitle="Here you can see the total dollar amounts received during the selected period, their total number of transactions, their average dollar per transaction, and most importantly the total number of senders they are receiving from."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      <Box
        m="20px 00"
        height="405px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .custom-data-grid:hover": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
          className="custom-data-grid"
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};
