import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import DenseTable from "../../components/DenseTable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { capitalize, formatCurrency, formatDate } from "../../utils";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { transactionAnalyzer } from "../../data/transactionAnalyzer";
import FunctionsIcon from "@mui/icons-material/Functions";
import ErrorIcon from "@mui/icons-material/Error";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TooltipPopup from "../../components/TooltipPopup/TooltipPopup";
import { AggregationCustomToolbar } from "../../components/CustomDatagridToolbar/CustomDatagridToolbar";
import { Link } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import CommentPopOver from "../../components/CommentPopOver";
import { getAggregations } from "../../data/Controller";
import DoneAllIcon from "@mui/icons-material/DoneAll";
function CustomToolbar(props) {
  return (
    <GridToolbar>{/* Add any other custom components here */}</GridToolbar>
  );
}

const CtrAggregation = ({
  aggregation = [],
  rawTransactions,
  setAggregation,
  setPeriodFrom,
  setPeriodTo,
  setLoader,
  setLoaderMessages,
  loaderMessages,
  auth,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const user = useAuthUser();

  const [ctrRequired, setCtrRequired] = useState([]);

  function notificationsLabel(count) {
    if (count === 0) {
      return "no transactions";
    }
    if (count > 10) {
      return "more than 10 transactions";
    }
    return `${count} transactions`;
  }
  const downloadReport = () => {
    const csvData = [
      [
        "name",
        "Description",
        "Alert type",
        "aggregation total",
        "No. Transactions",
      ],
      ...aggregation.map((row) => {
        // const recipients = [...row.transactions.recipients].join(" | ");
        let alert = "";
        if (row.ml) alert = "License Aggregation";
        if (row.mb) alert = "Branch Aggregation";
        return [
          row.name,
          row.transactions.license,
          alert,
          row.transactions.aggregation,
          row.transactions.transactions.length,
          // recipients,
        ];
      }),
    ];
    const blob = new Blob([csvData.join("\n")], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "MSB-Control-Aggregation-Report.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      hide: true,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.name)}
        </Typography>
      ),
    },
    {
      field: "alert",
      headerName: "Alerts",
      // flex: 1,
      minWidth: 100,
      cellClassName: "name-column--cell",
      renderCell: (data) => {
        return (
          <Stack direction="row" spacing={1}>
            {data.row.ml && (
              <TooltipPopup
                title="IMPORTANT: This customer has conducted transactions across multiple licenses within this business. 
              The warning is raised to alert you about potential risks or issues associated with such behavior."
                arrow
              >
                {/* <Chip
                  size="small"
                  icon={<ErrorIcon />}
                  label="ML"
                  color="error"
                /> */}
                <Typography>Multi-Licenses</Typography>
              </TooltipPopup>
            )}

            {data.row.ma && (
              <TooltipPopup
                title="IMPORTANT: This customer has conducted transactions or activities across different branches or locations within this business. 
                The warning is raised to alert you about potential risks or issues associated with such behavior."
                arrow
              >
                {/* <Chip
                  size="small"
                  icon={<PrivacyTipIcon />}
                  label="MB"
                  color="warning"
                /> */}
                <Typography>Multi-Branches</Typography>
              </TooltipPopup>
            )}
          </Stack>
        );
      },
    },

    {
      field: "agency",
      headerName: "Branch",
      hide: true,
      cellClassName: "name-column--cell",
      renderCell: (data) => {
        return (
          <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {capitalize(data.row.agency.join(" / "))}
          </Typography>
        );
      },
    },

    {
      field: "transaction",
      headerName: "aggregation",
      // flex: 1,
      hide: true,
      renderCell: (data) => {
        return formatCurrency(data.row.transactions.aggregation);
      },
    },
    {
      field: "date",
      headerName: "Date",
      hide: true,
      renderCell: (data) => {
        return formatDate(data.row.date);
      },
    },
    {
      field: "transactions",
      headerName: "Details",
      cellClassName: "",
      flex: 1,
      renderCell: ({
        row: {
          transactions: { aggregation, transactions },
        },
      }) => {
        return (
          transactions.length && (
            <Accordion
              square
              elevation={0}
              disableGutters
              defaultExpanded={true}
              style={{ backgroundColor: "transparent", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <IconButton
                    aria-label={notificationsLabel(transactions.length)}
                  >
                    <Badge badgeContent={transactions.length} color="secondary">
                      <AccountBalanceWalletIcon />
                    </Badge>
                  </IconButton>
                  Transactions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DenseTable data={transactions} aggregation={aggregation} />
              </AccordionDetails>
            </Accordion>
          )
        );
      },
    },
  ];

  const createKYC = (data) => {
    const customer = data.name;
    const branch = data.agency;
    const date = data.name;
    const beneficiary = data.transactions.recipients;
    const batchDate = data.batch_date;
    const amountAggregated = data.transactions.aggregation;
    const transactionsAvg = data.transactions.transactionAvg;
    const transactionfrequency = data.transactions.transactions.length;
  };

  useEffect(() => {
    const ctr = [];

    aggregation.forEach((record) => {
      if (record.transactions.aggregation >= 10000) ctr.push(record);
    });
    setCtrRequired(ctr);
  }, [aggregation]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box>
        <Header
          title="CTR Aggregation Analysis"
          subtitle={
            "Here, you will have access to a list of daily CTR threshold alerts for awareness and recordkeeping obligations. This includes sender, total dollar value aggregated, date, and transactions involved in the aggregation."
          }
        />
        <Box>
          {/* <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontWeight: "bold",
            }}
            small
            onClick={() => downloadReport()}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download
          </Button> */}
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontWeight: "bold",
            }}
            small
            onClick={() =>
              transactionAnalyzer(
                auth,
                rawTransactions,
                setAggregation,
                setPeriodFrom,
                setPeriodTo,
                setLoader,
                setLoaderMessages,
                loaderMessages
              )
            }
          >
            <FunctionsIcon sx={{ mr: "10px" }} />
            Calculate
          </Button>
        </Box>
      </Box>

      <Box
        m="40px 00"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
          checkboxSelection={false}
          getRowId={(row) => row._id}
          getRowHeight={() => "auto"}
          rows={ctrRequired}
          columns={columns}
          // components={{
          //   Toolbar: () => (
          //     <AggregationCustomToolbar
          //       handleSwitchChange={handleSwitchChange}
          //       switchState={switchState}
          //     />
          //   ),
          // }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default CtrAggregation;
