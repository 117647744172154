import { capitalize } from "../../utils";
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({ agency, setAgency }) {
  const [agencyName, setAgencyName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAgencyName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  React.useEffect(() => {
    setAgency({
      ...agency,
      current: agencyName,
    });
  }, [agencyName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <FormControl sx={{ ml: 2, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">All Branches</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={agencyName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {agency.list.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={agencyName.indexOf(name) > -1} />
              <ListItemText primary={capitalize(name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
