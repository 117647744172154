import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { formatDate, formatCurrency, capitalize } from "../../utils";
import { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ThresholdCustomToolbar } from "../../components/CustomDatagridToolbar/CustomDatagridToolbar";

export const Thresholds = ({ rawTransactions, threshold, setThreshold }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // eslint-disable-next-line no-unused-vars

  const [alignment, setAlignment] = useState("all");

  const handleChange = (event, newAlignment) => {
    let newThreshold = [];

    switch (newAlignment) {
      case "all":
        newThreshold = [...rawTransactions];
        break;
      case "t1":
        newThreshold = [
          ...rawTransactions.filter((d) => d.amount >= 1 && d.amount <= 999),
        ];
        break;
      case "t2":
        newThreshold = [
          ...rawTransactions.filter(
            (d) => d.amount >= 1000 && d.amount <= 2999
          ),
        ];
        break;
      case "t3":
        newThreshold = [
          ...rawTransactions.filter(
            (d) => d.amount >= 3000 && d.amount <= 9999
          ),
        ];
        break;

      case "t4":
        newThreshold = [...rawTransactions.filter((d) => d.amount >= 10000)];
        break;

      default:
        break;
    }

    setAlignment(newAlignment);
    setThreshold(newThreshold);
  };

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      field: "id",
      headerName: "Invoice No.",
    },
    {
      field: "license",
      headerName: "Principal",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.row.license || "--"}
        </Typography>
      ),
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.row.sender}
        </Typography>
      ),
    },
    {
      field: "receiver",
      headerName: "Beneficiary",
      cellClassName: "name-column--cell",
      flex: 1,
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.row.receiver}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      cellClassName: "name-column--cell",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ fontWeight: 1000 }}>
          {formatCurrency(params.row.amount) || formatCurrency(0)}
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      cellClassName: "",
      renderCell: (params) => (
        <Typography>{formatDate(params.row.date)}</Typography>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.country) || "--"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      cellClassName: "",
    },
  ];

  return (
    <Box m="20px">
      {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
      <Header
        title="Risk Concentration"
        subtitle="This section provides detailed information on the concentration of risk associated with transaction dollar values. This information will help you adopt a risk-based approach to assess the control environment of your AML and BSA compliance program, including multiple ranges and the overall percentages."
      />
      <Box
        m="40px 00"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Typography variant="h3" mb={2}>
          Select the Threshold to visualize
        </Typography>

        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="all">Show All</ToggleButton>
          <ToggleButton value="t1">Between $1 and $999</ToggleButton>
          <ToggleButton value="t2">Between $1,000 and $2,999</ToggleButton>
          <ToggleButton value="t3">Between $3,000 and $9,999</ToggleButton>
          <ToggleButton value="t4">
            Greater than or equal to $10,000
          </ToggleButton>
        </ToggleButtonGroup>

        <Typography variant="p" display="block" mt={2}>
          The records found in this threshold represents{" "}
          <Typography
            variant="strong"
            fontWeight={1000}
            color={colors.greenAccent[500]}
          >
            {parseInt((threshold.length / threshold.length) * 100) ||
              " less than 1"}
            %
          </Typography>{" "}
          of all the transactions analyzed.
        </Typography>
        <Typography variant="p" display="block" mb={3}>
          <Typography
            variant="strong"
            fontWeight={1000}
            color={colors.greenAccent[500]}
          >
            {threshold.length.toLocaleString("en-US")}{" "}
          </Typography>
          Records found.
        </Typography>

        <DataGrid
          // checkboxSelection
          rows={threshold}
          // getRowId={(row) => row._id}
          columns={columns}
          autoHeight
          pageSize={25}
          components={{ Toolbar: ThresholdCustomToolbar }}
          disableSelectionOnClick
        />
      </Box>
      {/* </Box> */}
    </Box>
  );
};
