import { Box, Typography, useTheme } from "@mui/material";
import { useRef, useEffect } from "react";
import Header from "../components/Header";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { mockBarData as data } from "../data/mockData";
import { calculateTransactionThresholds } from "../utils";
import { init } from "echarts";

const BarChart = ({ rawTransactions = [] }) => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const myChartRef = init(chartRef.current);
    const transactions = calculateTransactionThresholds(rawTransactions);
    const data = transactions.map((record) => record.avg.toFixed(0));

    // Define the options for the chart
    const options = {
      grid: {
        left: "4%",
        right: "15%",
        bottom: "3%",
        top: "7%",
        containLabel: true,
        height: "400px", // Set the height of the grid to 80% of the available space
      },
      xAxis: {
        max: "dataMax",
      },
      yAxis: {
        type: "category",
        data: transactions.map((record) => record.category),
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
      },
      series: [
        {
          realtimeSort: true,
          name: "X",
          type: "bar",
          data: data,
          barWidth: 30,
          itemStyle: {
            color: colors.blueAccent[600], // Use a light blue color
          },
          label: {
            show: true,
            position: "right",
            valueAnimation: true,
            color: colors.greenAccent[500],
          },
        },
      ],

      animationDuration: 0,
      animationDurationUpdate: 3000,
      animationEasing: "linear",
      animationEasingUpdate: "linear",
      // barCategoryGap: "70%",
      // barGap: "20%",
    };

    myChartRef.setOption(options);

    // add event listener to resize the chart when the window size changes
    window.addEventListener("resize", () => {
      myChartRef.resize();
    });
  }, [rawTransactions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="20px" height="100%">
      <Header
        title=""
        subtitle="This section provides detailed information on the concentration of risk associated with transaction dollar values, including multiple ranges and overall percentages. This will help you adopt a risk-based approach to assess the control environment of your AML and BSA compliance program."
      />
      <div
        id="BarChart"
        ref={chartRef}
        style={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
};

export default BarChart;
