import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { capitalize, formatDate, formatCurrency } from "../utils";
import EastIcon from "@mui/icons-material/East";

export default function DenseTable({ data = [], aggregation = [] }) {
  return (
    <TableContainer style={{ marginTop: 20, marginBottom: 20 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Sender</TableCell>
            <TableCell></TableCell>
            <TableCell>Recipient</TableCell>
            <TableCell align="right">Branch</TableCell>
            <TableCell align="right">license</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, key) => {
            return (
              <TableRow key={key}>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {capitalize(row.sender)}
                </TableCell>
                <TableCell>
                  <EastIcon />
                </TableCell>
                <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                  {capitalize(row.receiver)}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {row.agency}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {row.license || "--"}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {row.country || "--"}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {formatDate(row.date)}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {formatCurrency(row.amount)}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
                fontWeight: "1000",
              },
            }}
          >
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">TOTAL</TableCell>
            <TableCell align="right">{formatCurrency(aggregation)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
