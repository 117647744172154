import {
  getTransactionsStatus,
  ConvertToNumber,
  formatCurrency,
} from "../../utils";
import { createPDF } from "../../utils/createPDF";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import WifiFindIcon from "@mui/icons-material/WifiFind";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Header from "../../components/Header";
// import Map from "../../components/Map/Map";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { useContext, useEffect } from "react";
import { Context } from "../../context";
import DonutChart from "../../components/DonutChart";
import { ThresholdDashboardTable } from "../../components/ThresholdDashboardTable/ThresholdDashboardTable";
import { CountriesDashboardTable } from "../../components/CountriesDashboardTable/CountriesDashboardTable";
import { LicenseDashboardTable } from "../../components/LicenseDashboardTable/LicenseDashboardTable";
import { SendersDashboardTable } from "../../components/SendersDashboardTable/SendersDashboardTable";
import { ReceiversDashboardTable } from "../../components/ReceiversDashboardTable/ReceiversDashboardTable";

const Dashboard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // eslint-disable-next-line no-unused-vars
  const {
    loader,
    setLoader,
    periodFrom,
    setPeriodFrom,
    periodTo,
    setPeriodTo,
  } = useContext(Context);

  const paidTransactions = getTransactionsStatus(props.transactions, "Paid");
  const cancelledTransactions = getTransactionsStatus(
    props.transactions,
    "Cancelled"
  );
  const rawTransactions = props.rawTransactions;
  const transactionsByCountry = props.transactionsByCountry;
  const transactionsByCountryMax = props.transactionsByCountryMax;
  const transactionsByLicense = props.transactionsByLicense;
  const transactionsByLicenseMax = props.transactionsByLicenseMax;

  const totalAmountSent = formatCurrency(
    props.transactions
      ? props.transactions.reduce(
          (acc, cur) => ConvertToNumber(cur.amount) + acc,
          0
        )
      : []
  );
  // let aggregationSpan = 12;
  // if (transactionsByCountry.length < 2) aggregationSpan -= 6;
  // if (transactionsByLicense.length < 2) aggregationSpan -= 6;

  // if (transactionsByCountry.length > 1 && transactionsByLicense.length > 1) {
  //   aggregationSpan = 12;
  // } else if (
  //   transactionsByCountry.length === 1 &&
  //   transactionsByLicense.length === 1
  // ) {
  //   aggregationSpan = 12;
  // } else {
  //   aggregationSpan = 6;
  // }

  // return props.transactions.length > 0 ? (
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          className="not-for-report "
          title="DASHBOARD"
          subtitle="Here, you will find a high-level overview of your business's money remittance transactional profile. This includes transactions conducted via all LMTRs principals for which you act as an agent on their behalf."
        />
        <Header
          className="for-report"
          title="REPORT"
          subtitle="Here, you will find a high-level overview of your business's money remittance transactional profile. This includes transactions conducted via all LMTRs principals for which you act as an agent on their behalf."
        />
        <Box className="not-for-report">
          {/* <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: 2,
            }}
            href="/assets/template.xls"
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Template
          </Button> */}
          {/* <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: 2,
            }}
            onClick={() =>
              createPDF(props.auth, rawTransactions, props.aggregation)
            }
          >
            download
          </Button> */}
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      {props.transactions?.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* 1. TOTAL AGGREGATION*/}
          <Box
            gridColumn="span 2"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={props.findings.length.toLocaleString("en-US")}
              subtitle="Total aggregations"
              // total={totalAmountAggregated}
              to="/kyc-aggregation"
              progress={String(
                parseFloat(
                  props.findings.length / paidTransactions.length
                ).toFixed(2)
              )}
              increase={
                parseFloat(
                  (props.findings.length / paidTransactions.length) * 100
                )
                  .toFixed(2)
                  .toLocaleString(undefined, { style: "percent" }) + "%"
              }
              icon={
                <WifiFindIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 3. TOTAL COUNT AND DOLLAR VALUE*/}
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={props.transactions?.length.toLocaleString("en-US")}
              subtitle="Total count and Dollar value"
              total={totalAmountSent}
              to="/transactions"
              // progress="0.50"
              // increase={"5%"}
              icon={
                <MultipleStopIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 3. CANCELLED TRANSACTIONS*/}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={cancelledTransactions.length.toLocaleString("en-US")}
              subtitle="Cancelled transactions"
              progress={String(
                parseFloat(
                  cancelledTransactions.length / props.transactions?.length
                ).toFixed(2)
              )}
              increase={
                String(
                  parseFloat(
                    cancelledTransactions.length / props.transactions?.length
                  ).toFixed(2) * 100
                ) + "%"
              }
              icon={
                <ReceiptIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 4. PAID TRANSACTIONS*/}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={paidTransactions.length.toLocaleString("en-US")}
              subtitle="Paid transactions"
              progress={String(
                parseFloat(
                  paidTransactions.length / props.transactions?.length
                ).toFixed(2)
              )}
              increase={
                String(
                  parseFloat(
                    paidTransactions.length / props.transactions?.length
                  ).toFixed(2) * 100
                ) + "%"
              }
              icon={
                <ReceiptIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          {/* 6. TRANSACTION FREQUENCY PER COUNTRY*/}
          {transactionsByCountry.length > 1 && (
            <Box
              gridColumn="span 12"
              gridRow="span 5"
              backgroundColor={colors.primary[400]}
              padding="30px"
            >
              <Typography
                variant="h5"
                className="title-print chart-title "
                fontWeight="600"
              >
                Transactions Frequency per Country
              </Typography>
              <Box style={{ textAlign: "center" }}>
                <DonutChart
                  rawTransactions={rawTransactions}
                  data={transactionsByCountry}
                  max={transactionsByCountryMax}
                />
              </Box>
            </Box>
          )}

          {/* 5. TOTAL AMOUNT SENT PER COUNTRY*/}
          <Box
            className="page-break not-for-report  for-report"
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              className="title-print"
              sx={{ marginBottom: "15px" }}
            >
              Total Amount Sent per Country
            </Typography>
            <CountriesDashboardTable rawTransactions={rawTransactions} />
          </Box>
          <Box
            className="page-break table-for-report"
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              className="title-print"
              sx={{ marginBottom: "15px" }}
            >
              Total Amount Sent per Country
            </Typography>
            <CountriesDashboardTable
              printView={true}
              rawTransactions={rawTransactions}
            />
          </Box>

          {/* 8. FREQUENCY SENT PER LICENSE*/}
          {transactionsByLicense.length > 1 && (
            <Box
              gridColumn="span 12"
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              padding="30px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "15px" }}
              >
                Transactions Frequency per License
              </Typography>
              <Box>
                <DonutChart
                  rawTransactions={rawTransactions}
                  data={transactionsByLicense}
                  max={transactionsByLicenseMax}
                />
              </Box>
            </Box>
          )}

          {/* 7. AMOUNT SENT PER LICENSE*/}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Money Transmitter Activity
            </Typography>
            <LicenseDashboardTable rawTransactions={rawTransactions} />
          </Box>

          {/* 10. THRESHOLD PERCENTAGE*/}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Risk Concentration Percentages
            </Typography>
            <BarChart rawTransactions={rawTransactions} />
          </Box>

          {/* 9. THRESHOLD*/}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Risk Concentration
            </Typography>
            <ThresholdDashboardTable rawTransactions={rawTransactions} />
          </Box>

          {/* 11. TOP SENDERS*/}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Top Senders
            </Typography>
            <SendersDashboardTable rawTransactions={rawTransactions} />
          </Box>

          {/* 12. TOP BENEFICIARIES*/}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
            padding="30px"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Top Beneficiaries
            </Typography>
            <ReceiversDashboardTable rawTransactions={rawTransactions} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
// ) : (
//   !loader && (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       alignContent="center"
//       height="calc(100% - 80px)"
//     >
//       <Box mb="30px">
//         <Typography
//           variant="h2"
//           color={colors.grey[100]}
//           fontWeight="bold"
//           sx={{ m: "0 0 5px 0" }}
//         >
//           Upload Transactions
//         </Typography>
//         <Typography
//           align="center"
//           variant="h5"
//           color={colors.greenAccent[400]}
//         >
//           Welcome to your dashboard
//         </Typography>
//       </Box>
//       <Box>
//         <Button
//           sx={{
//             backgroundColor: colors.blueAccent[700],
//             color: colors.grey[100],
//             fontSize: "14px",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             marginLeft: 1,
//           }}
//           variant="contained"
//           component="label"
//         >
//           Upload
//           <input
//             hidden
//             type="file"
//             onChange={async (e) => {
//               const file = e.target.files[0];
//               fileReader(
//                 file,
//                 setRawTransactions,
//                 setLoaderMessages,
//                 loaderMessages,
//                 credentials,
//                 auth
//               );
//               setLoader(true);
//             }}
//           />
//         </Button>
//       </Box>
//     </Box>
//   )
// );
export default Dashboard;

{
  /* ROW 3 */
}
{
  /* <Box
            gridColumn={`span ${aggregationSpan}`}
            gridRow="span 3"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
            >
              <Typography
                color={colors.grey[100]}
                variant="h5"
                fontWeight="600"
              >
                Aggregations Overview
              </Typography>
            </Box>
            {props.findings.map((transaction, i) => (
              <Box
                key={`${transaction.idd}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.name}
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    <strong>{transaction.transactions.recipients.size}</strong>{" "}
                    recipients related
                  </Typography>
                </Box>

                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  $
                  {transaction.transactions.aggregation.toLocaleString("en-US")}
                </Box>
              </Box>
            ))}
          </Box> */
}
