import { jsPDF } from "jspdf";
import {
  formatCurrency,
  groupAmountsBy,
  calculateTransactionThresholds,
  getTop10,
} from "../utils";
const doc = new jsPDF();

function NormalizeLicenseTableData(arr) {
  const options = {
    style: "currency",
    currency: "USD", // Currency code for US Dollar
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  for (let obj of arr) {
    for (const key in obj) {
      if (key === "frequency" || key === "partner") {
        obj[key] = Number(obj[key]).toLocaleString("en-US"); // Convert number to string
      } else if (key === "avg" || key === "amount") {
        obj[key] = Number(obj[key]).toLocaleString("en-US", options); // Convert number to string
      } else if (key === "ma") {
        if (obj[key] === true && obj["kyc"] !== "Required") {
          obj["kyc"] = "Required";
        }
      } else if (key === "ml") {
        if (obj[key] === true && obj["kyc"] !== "Required") {
          obj["kyc"] = "Required";
        }
      } else {
        obj[key] = obj[key].toString();
      }
      if (obj[key]["transactions"] && obj[key]["transactions"]["aggregation"]) {
        obj["amount"] = Number(
          obj[key]["transactions"]["aggregation"]
        ).toLocaleString("en-US");
      }
    }
  }
}

export const createPDF = (auth, rawTransactions, aggregation) => {
  let marginLeft = 10;
  let marginTop = 150;
  const tableFontSize = 8;
  const pageWidth = doc.internal.pageSize.width - 20;
  const agentName = auth().agency;

  // Calculating data
  const licenseData = groupAmountsBy(rawTransactions, "license");
  const riskConcentration = calculateTransactionThresholds(rawTransactions);
  const transactionPerCountry = groupAmountsBy(rawTransactions, "country");
  const topSenders = getTop10(rawTransactions, "sender").slice(0, 20);
  const topBeneficiaries = getTop10(rawTransactions, "receiver").slice(0, 20);
  const aggregationData = [...aggregation];

  const cellSize = 67;

  // Normalizing data
  NormalizeLicenseTableData(licenseData);
  NormalizeLicenseTableData(riskConcentration);
  NormalizeLicenseTableData(transactionPerCountry);
  NormalizeLicenseTableData(topSenders);
  NormalizeLicenseTableData(topBeneficiaries);
  NormalizeLicenseTableData(aggregationData);

  console.log(aggregation);

  const createLicenseTable = (doc, licenseData) => {
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: cellSize,
          align: keys[i].align,
          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      {
        align: "right",
        field: "name",
        label: "Money Transmitter",
      },
      { align: "center", field: "frequency", label: "# of Trx Sent" },
      { align: "center", field: "avg", label: "avg" },
      { align: "center", field: "amount", label: "Avg $ Amount/Trx" },
    ]);

    doc.table(marginLeft, 20, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  const createRiskTable = (doc, licenseData) => {
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: 90,
          align: keys[i].align,
          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      { align: "right", field: "category", label: "Description" },
      { align: "center", field: "frequency", label: "# of Trx Sent" },
      { align: "center", field: "avg", label: "avg" },
    ]);

    doc.table(marginLeft, 90 + licenseData.length * 10, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  const createCountryTable = (doc, licenseData) => {
    const width = 57;
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: keys[i].width,
          align: keys[i].align,
          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      { width: 97, align: "right", field: "name", label: "Country" },
      { width, align: "center", field: "frequency", label: "# of Trx Sent" },
      { width, align: "center", field: "avg", label: "avg" },
      { width, align: "center", field: "amount", label: "Total Sent" },
    ]);

    doc.table(marginLeft, 30, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  const createSenderTable = (doc, licenseData) => {
    const width = 44;
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: keys[i].width,
          align: keys[i].align,
          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      { align: "right", field: "name", label: "Sender", width: width + 40 },
      { align: "center", field: "frequency", label: "# of Trx Sent", width },
      { align: "center", field: "avg", label: "Trx Avg", width },
      { align: "center", field: "amount", label: "Total Sent", width },
      { align: "center", field: "partner", label: "# of Beneficiaries", width },
    ]);

    doc.table(marginLeft, 30, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  const createBeneficiaryTable = (doc, licenseData) => {
    const width = 44;
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: keys[i].width,
          align: keys[i].align,

          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      {
        align: "right",
        field: "name",
        label: "Beneficiary",
        width: width + 40,
      },
      { align: "center", field: "frequency", label: "# of Trx Sent", width },
      { align: "center", field: "avg", label: "Trx Avg", width },
      { align: "center", field: "amount", label: "Total Sent", width },
      { align: "center", field: "partner", label: "# of Senders", width },
    ]);

    doc.table(marginLeft, 30, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  const createAggregationTable = (doc, licenseData) => {
    const width = 44;
    function createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i].field,
          name: keys[i].field,
          prompt: keys[i].label,
          width: keys[i].width,
          align: keys[i].align,

          padding: 10,
        });
      }
      return result;
    }

    var headers = createHeaders([
      {
        align: "right",
        field: "name",
        label: "Sender",
        width: width + 40,
      },
      { align: "center", field: "date", label: "Date", width },
      { align: "center", field: "kyc", label: "KYC Requirement", width },
      { align: "center", field: "amount", label: "Total Sent", width },
      { align: "center", field: "partner", label: "Beneficiaries", width },
    ]);

    doc.table(marginLeft, 30, licenseData, headers, {
      fontSize: tableFontSize,
    });
  };

  doc.setFontSize(22);
  doc.text("GLOBAL TRANSACTIONS REPORT", marginLeft, marginTop); // Title
  doc.line(marginLeft, marginTop + 5, 200, marginTop + 3); // horizontal line
  doc.setFontSize(12);
  doc.text(agentName, marginLeft, (marginTop += 13)); // Agent name
  doc.text(
    `generated: ${new Date().toUTCString()}`,
    marginLeft,
    (marginTop += 6)
  );

  doc.addPage("letter", "p");
  doc.text(
    `During the above period, we have analyzed a subset of remittance orders for a payment \nrequested by ${agentName.replace(
      ".",
      ""
    )} to its LMTRs`,
    115,
    10,
    null,
    null,
    "center"
  );
  createLicenseTable(doc, licenseData); // License table

  //   TRANSACTIONS PER LICENSE
  doc.setFontSize(18);
  doc.text(
    `Risk Concentration`,
    115,
    65 + licenseData.length * 10,
    null,
    null,
    "center"
  );
  doc.setFontSize(12);
  createRiskTable(doc, riskConcentration); // License table

  //   TRANSACTIONS PER COUNTRY
  doc.addPage("letter", "p");
  doc.setFontSize(18);
  doc.text(`Amount sent per countries`, 115, 20, null, null, "center");
  doc.setFontSize(12);
  createCountryTable(doc, transactionPerCountry); // country table

  //   TRANSACTIONS PER SENDER
  doc.addPage("letter", "p");
  doc.setFontSize(18);
  doc.text(
    `Top 20 Senders Transactional Behavior`,
    115,
    20,
    null,
    null,
    "center"
  );
  doc.setFontSize(12);
  createSenderTable(doc, topSenders); // Sender table

  //   TRANSACTIONS PER BENEFICIARY
  doc.addPage("letter", "p");
  doc.setFontSize(18);
  doc.text(
    `Top 20 Beneficiaries Transactional Behavior`,
    115,
    20,
    null,
    null,
    "center"
  );
  doc.setFontSize(12);
  createBeneficiaryTable(doc, topBeneficiaries); // Beneficiary table

  //   AGGREGATION DATA
  doc.addPage("letter", "p");
  doc.setFontSize(18);
  doc.text(
    `Transactions for more than US$3,000 and less than US$10,000 (Require Record-Keeping)`,
    115,
    20,
    null,
    null,
    "center"
  );
  doc.setFontSize(12);
  //   createAggregationTable(doc, aggregationData); // Beneficiary table

  doc.save("a4.pdf");
};
