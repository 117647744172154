import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { formatCurrency, formatDate, capitalize } from "../../utils";
import { TransactionCustomToolbar } from "../../components/CustomDatagridToolbar/CustomDatagridToolbar";
import { useLocation } from "react-router-dom";

export const Transactions = ({ rawTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const selection = location?.state?.selection;
  const field = location?.state?.field;

  const columns = [
    {
      field: "id",
      headerName: "Invoice No.",
      // flex: 1,
    },
    {
      field: "license",
      headerName: "Principal",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.row.license || "--"}
        </Typography>
      ),
    },
    {
      field: "agency",
      headerName: "Branch",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.agency)}
        </Typography>
      ),
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,

      // minWidth: 0,
      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {capitalize(params.row.sender)}
        </Typography>
      ),
    },
    {
      field: "receiver",
      headerName: "Beneficiary",
      flex: 1,

      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {capitalize(params.row.receiver)}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      // flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ fontWeight: 1000 }}>
          {formatCurrency(params.row.amount) || formatCurrency(0)}
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      // flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.date)}</Typography>
      ),
    },
    {
      field: "country",
      headerName: "Country",

      wrap: true,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography
          noWrap
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
        >
          {capitalize(params.row.country) || "--"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      // flex: 1,
      cellClassName: "",
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Transactions Log"
        subtitle="Here, you can access a log containing information about all money remittance transactions conducted through the LMTR principals for whom you act as an agent. This log includes details such as the invoice number, sender, receiver, transaction date and amount, as well as the LMTR through which the transaction was conducted."
      />

      <Box
        m="40px 00"
        height="75vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          style={{ width: "100%" }}
          rows={rawTransactions}
          columns={columns}
          components={{ Toolbar: TransactionCustomToolbar }}
          // columnWidth="auto"
          disableSelectionOnClick
          initialState={{
            ...rawTransactions,
            filter: {
              ...rawTransactions?.filter,
              filterModel: {
                items: [
                  {
                    columnField: field,
                    operatorValue: "contains",
                    value: selection,
                  },
                ],
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
