import { DB as db } from "../config/constants";

export const getTransactionsBySender = async (id, sender) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/transactions_by_sender";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_id: id,
      sender,
      db,
    }),
  });

  const result = await response.json();
  return result;
};
export const getTransactions = async (id, agency, from, to) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/transactions_by_period";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_id: id,
      agency: agency,
      from: from,
      to: to,
      db,
    }),
  });

  const result = await response.json();
  return result;
};
export const get_kyc_by_id = async (kycId) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/get_kyc_by_id";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      kycId,
      db,
    }),
  });

  const result = await response.json();
  return result;
};

export const getAggregationsBySender = async (id, sender) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/aggregations_by_sender";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_id: id,
      sender: sender,
      db,
    }),
  });

  const result = await response.json();

  return result;
};

export const aggregationEdit = async (id, comments) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/aggregation_edit";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_id: id,
      comments: comments,
      db,
    }),
  });

  const result = await response.json();

  return result;
};

export const aggregationKyc = async (name, kyc, kycId) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/aggregation_kyc";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      kyc,
      kycId,
      db,
    }),
  });

  const result = await response.json();

  return result;
};

export const getAggregations = async (id, agency, from, to) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/aggregation_by_period";
  // const url =
  //   "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/aggregations";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent_id: id,
      agency: agency,
      from: from,
      to: to,
      db,
    }),
  });

  const result = await response.json();

  return result;
};

export const insertAggregation = async (content, id) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/insert_aggregation";

  const records = content.map((r) => {
    r.agent_id = id;
    r.db = db;
    return r;
  });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      db,
      data: records,
    }),
  });

  const result = await response.json();
  return result;
};

export const insertTransaction = async (content, setRawTransaction) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/insert_transaction";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      db,
      data: content,
    }),
  });

  const result = await response.json();
  if (result.body === "success") {
    return content;
  }
};

export const login = async (credentials, setUser) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/login";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });

  const result = await response.json();

  return result;
};

export const insertKyc = async (content) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/insert_kyc";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      db,
      data: [content],
    }),
  });

  const result = await response.json();
  return result;
};

export const getKycList = async (id) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/get_kyc_list";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      db,
      agent_id: id,
    }),
  });

  const result = await response.json();
  return result;
};

export const downloadMSBReport = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = 'https://msb.evdpl.com/wp-json/mymsb/v1/data';

      const response = await fetch(url, { method: "POST", body: formData });

      if (!response.ok) {
        reject('Network response was not ok');
      }

      const blob = await response.blob();
      resolve(blob);
    } catch (error) {
      reject(error.message)
    }
  });
}

export const userAuthentication = async (reqBody) => {
  const url =
    "https://us-east-1.aws.data.mongodb-api.com/app/msb-control-transaction-analyzer-zoqxp/endpoint/user_authentication";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqBody),
  });

  const result = await response.json();

  return result;
};
