import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Context } from "../../context";
import { useContext } from "react";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export const TransactionCustomToolbar = ({ record }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const { period } = useContext(Context);
  const [switchState, setSwitchState] = useState(false); // eslint-disable-line no-unused-vars
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `Transactions-Log [${period.from} - ${period.to}]`,
        }}
      />
    </GridToolbarContainer>
  );
};

export const ThresholdCustomToolbar = ({ record }) => {
  const { period } = useContext(Context);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `Threshold [${period.from} - ${period.to}]`,
        }}
      />
    </GridToolbarContainer>
  );
};

// export const AggregationCustomToolbar = ({ record }) => {
//   const { period } = useContext(Context);
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//     </GridToolbarContainer>
//   );
// };

export const AggregationCustomToolbar = ({
  switchState,
  handleSwitchChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <GridToolbarContainer>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </Box>
          <Box>
            <FormControlLabel
              color={colors.blueAccent[500]}
              control={
                <Switch
                  onChange={handleSwitchChange}
                  color="secondary"
                  checked={switchState}
                />
              }
              label={switchState ? "Grouping by KYC" : "Grouping by CTR"}
            />
          </Box>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};
