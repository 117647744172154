import { Box, Typography, useTheme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../utils";
import { ConvertToNumber } from "../../utils";

export const TransactionalBehavior = ({ rawTransactions }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [top, setTop] = useState([]); // eslint-disable-line no-unused-vars
  const [range, setRange] = useState([]); // eslint-disable-line no-unused-vars
  const [select, setSelect] = useState([]); // eslint-disable-line no-unused-vars
  const [switchState, setSwitchState] = useState(false); // eslint-disable-line no-unused-vars

  const columns = [
    {
      field: "sender",
      headerName: switchState ? "Sender" : "Beneficiary",
      flex: 1,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      // flex: 1,
    },
    {
      field: "recipients",
      headerName: switchState ? "Beneficiaries" : "Senders",
      // flex: 1,
    },
    {
      field: "avg",
      headerName: "Average",
      // flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[300]}>
          {formatCurrency(params.row.avg)}
        </Typography>
      ),
    },
    {
      field: "value",
      headerName: "Total Amount",

      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography color={colors.greenAccent[300]}>
          {formatCurrency(params.row.value)}
        </Typography>
      ),
    },
  ];

  const handleSwitchChange = (e) => {
    setSwitchState(e.target.checked);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <GridToolbar />
            </Box>
            <Box>
              <FormControlLabel
                color={colors.blueAccent[500]}
                control={
                  <Switch
                    onChange={handleSwitchChange}
                    color="secondary"
                    defaultChecked={switchState}
                  />
                }
                label={
                  switchState
                    ? "Grouping by Senders"
                    : "Grouping by Beneficiaries"
                }
              />
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    //TODO: USE RAW TRANSACIONS TO CALCULATE TOP 10
    const customers = {};
    const senders = [];
    let id = 0;

    for (let transaction of rawTransactions) {
      let customer = transaction.sender;
      if (!switchState) {
        customer = transaction.receiver;
      }
      const amount = ConvertToNumber(transaction.amount);

      if (!customers[customer])
        customers[customer] = {
          recipients: new Set(),
          id: id + 1,
          value: 0,
          sender: "",
          frequency: 0,
          avg: 0,
        };

      id++;
      customers[customer].value += amount;
      customers[customer].sender = customer;
      customers[customer].frequency++;
      customers[customer].recipients.add(transaction.receiver);
      customers[customer].avg =
        customers[customer].value / customers[customer].frequency;
    }

    for (let key in customers) {
      customers[key].recipients = Array.from(customers[key].recipients).length;
      senders.push(customers[key]);
    }
    const sorted = senders.sort((a, b) => {
      return b.value - a.value;
    });

    setRange(sorted);
    setTop(sorted);
  }, [rawTransactions, switchState]);

  return (
    <Box m="20px">
      <Header
        title="Transactional Behavior"
        subtitle="Here, you can obtain detailed information about all individual money remittance senders that have conducted transactions through the LMTR principals for whom you act as an agent. The log includes details such as the total number of transactions, the average dollar value of each transaction, and the total dollar volume of remittances sent."
      />

      <Box
        m="40px 00"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection={false}
          rows={range}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};
