import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { formatCurrency, groupAmountsBy } from "../../utils";
import Header from "../../components/Header";

export const LicenseDashboardTable = ({ rawTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tableData = groupAmountsBy(rawTransactions, "license");

  const columns = [
    {
      field: "name",
      headerName: "Principal",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Total Amount Sent",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{formatCurrency(params.row.amount)}</Typography>
      ),
    },
    {
      field: "frequency",
      headerName: "Number of Trx",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => (
        <Typography>{params.row.frequency.toLocaleString("en-US")}</Typography>
      ),
    },
    {
      field: "avg",
      headerName: "Transaction Amount Avg",
      minWidth: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Typography>{formatCurrency(Number(params.row.avg))}</Typography>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title=""
        subtitle="Here you have access to inside information related to the amounts sent per Money Transmitter, including the total dollar amount, total number of transactions, and the average dollar amount sent."
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      <Box
        m="20px 00"
        height="405px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};
