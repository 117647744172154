import { Box, useTheme, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAuthUser } from "react-auth-kit";
import CommentPopOver from "../../components/CommentPopOver";

import {
  capitalize,
  formatPhoneNumber,
  formatCurrency,
  formatDate,
  addMonths,
} from "../../utils";
import {
  getTransactionsBySender,
  getAggregationsBySender,
  aggregationEdit,
} from "../../data/Controller";
import { DataGrid } from "@mui/x-data-grid";
import { TransactionCustomToolbar } from "../../components/CustomDatagridToolbar/CustomDatagridToolbar";

const Alert = ({ a, b, color, comments }) => {
  if (comments) {
    return (
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot style={{ background: color.greenAccent[500] }}>
          <CheckCircleIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
    );
  }
  if (a === true || b === true) {
    return (
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot style={{ background: color.redAccent[500] }}>
          <GppMaybeIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
    );
  }

  return (
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineConnector />
    </TimelineSeparator>
  );
};

const KycDetails = ({ rawTransactions, getTransactionsFromServer }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [state, setState] = useState({});
  const [transactionsList, setTransactionsList] = useState([]);
  const [distinctNames, setDistinctNames] = useState([]);
  const [distinctCountries, setDistinctCountries] = useState([]);
  const [aggregationsList, setAggregationsList] = useState([]);
  const [searchParams] = useSearchParams();
  const getRowId = (row) => row.id;
  const auth = useAuthUser();

  const columns = [
    {
      field: "id",
      headerName: "Invoice No.",
      // flex: 1,
    },
    {
      field: "license",
      headerName: "Principal",
      align: "left",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.row.license || "--"}
        </Typography>
      ),
    },
    {
      field: "agency",
      headerName: "Branch",
      flex: 1,
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.agency)}
        </Typography>
      ),
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1,
      // minWidth: 0,
      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {capitalize(params.row.sender)}
        </Typography>
      ),
    },
    {
      field: "receiver",
      headerName: "Beneficiary",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {capitalize(params.row.receiver)}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      // flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ fontWeight: 1000 }}>
          {formatCurrency(params.row.amount) || formatCurrency(0)}
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      // flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.date)}</Typography>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      wrap: true,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Typography
          noWrap
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
        >
          {capitalize(params.row.country) || "--"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      // flex: 1,
      cellClassName: "",
    },
  ];
  const columnsReceiver = [
    {
      field: "id",
      headerName: "No.",
      // flex: 1,
    },
    {
      field: "receiver",
      headerName: "Beneficiary",
      align: "left",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.receiver)}
        </Typography>
      ),
    },
  ];
  const columnsCountries = [
    {
      field: "id",
      headerName: "No.",
      // flex: 1,
    },
    {
      field: "country",
      headerName: "country",
      align: "left",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {capitalize(params.row.country)}
        </Typography>
      ),
    },
  ];
  const style = {
    width: "100%",
    bgcolor: colors.primary[400],
    overflow: "auto",
    maxHeight: 340,
  };

  const calculateGridHeight = (row) => {
    // Assuming each row has a height of 52 pixels (adjust as needed)
    const rowHeight = 76;
    const headerHeight = 56; // Height of the header

    // Calculate the total height
    const totalHeight = headerHeight + row.length * rowHeight;

    // Add some extra padding if desired
    const padding = 20;
    const gridHeight = totalHeight + padding;

    const minHeight = 250; // Minimum height to prevent the grid from collapsing completely

    const newHeight = Math.max(gridHeight, minHeight);
    return Math.max(newHeight, minHeight);
  };
  const gridHeight = calculateGridHeight(transactionsList);
  const beneficiariesHeight = calculateGridHeight(distinctNames);
  const countriesHeight = calculateGridHeight(distinctCountries);

  const getTransactions = async (target) => {
    const transactions = [];
    for (let transaction of rawTransactions) {
      const sender = transaction.sender.toLowerCase().replace(/\s/g, "");
      if (target === sender) transactions.push(transaction);
    }

    setTransactionsList(transactions);
  };

  useEffect(() => {
    const data = {};
    let target = null;
    for (const [key, value] of searchParams.entries()) {
      data[key] = value;
    }
    if (data.senderFullName) {
      target = data.senderFullName.toLowerCase().replace(/\s/g, "");
    }

    if (target) {
      getTransactions(target);
    }
    setState(data);
  }, []);

  const getAggregations = async () => {
    const transactions = await getAggregationsBySender(
      state.agent_id,
      state.senderFullName
    );
    setAggregationsList(transactions);
  };

  // useEffect(() => {
  //   const data = {};
  //   for (const [key, value] of searchParams.entries()) {
  //     data[key] = value;
  //   }

  //   setState(data);
  // }, []);

  useEffect(() => {
    if (state.agent_id && state.senderFullName) {
      getTransactions(state.senderFullName.toLowerCase().replace(/\s/g, ""));
      getAggregations();
    }
  }, [state]);

  useEffect(() => {
    const s = Array.from(new Set(transactionsList.map((row) => row.receiver)));
    setDistinctNames(s);
  }, [transactionsList]);

  useEffect(() => {
    const s = Array.from(new Set(transactionsList.map((row) => row.country)));
    setDistinctCountries(s);
  }, [distinctNames]);

  return (
    <Box m="20px">
      <Header
        title="Know your customer"
        subtitle={`This report summarizes the information collected and verified during the KYC process for ${state.senderFullName} . It provides a comprehensive overview of the customer's identity, background, and financial information, to assess the customer's risk level and compliance with regulatory requirements.`}
      />

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        mt="40px"
      >
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justyItems: "space-between",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid item xs>
                <Typography gutterBottom variant="h4" component="div">
                  KYC Record
                </Typography>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography gutterBottom variant="h6" component="div">
                    created at:{" "}
                    {new Date(state.createdDate).toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
              <Typography color="text.secondary" variant="body2"></Typography>

              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="KYC Generated By "
                    secondary={state.createdBy}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="KYC Form Triggered By "
                    secondary={state.transactionType}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Agency"
                    secondary={capitalize(state.branch)}
                  />
                </ListItem>
                <Divider />
                {/* <ListItem>
                  <ListItemText
                    primary="KYC Expiration Date"
                    secondary={formatDate(addMonths(state.createdDate, 12))}
                  />
                </ListItem>
                <Divider /> */}
              </List>
            </Box>

            {/* <Box sx={{ m: 2 }}>
              <Typography gutterBottom variant="body1">
                Risk Level
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip label="Low" />
                <Chip color="info" label="Medium" />
                <Chip label="High" />
              </Stack>
            </Box> */}
          </Box>
        </Box>

        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box>
            <Box
              sx={{
                width: "100%",

                bgcolor: colors.primary[400],
                padding: 2,
              }}
            >
              <Box sx={{ my: 2, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h4" component="div">
                      Sender
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ m: 2 }}>
                <List sx={style} component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <ListItemText
                      primary="Name"
                      secondary={state.senderFullName}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="gender"
                      secondary={state.senderGender}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Nationality"
                      secondary={state.senderNationality}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Phone"
                      secondary={formatPhoneNumber(state.senderPhone)}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Occupation" secondary="Engineer" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Date of birth"
                      secondary={new Date(
                        state.senderBirthDate
                      ).toLocaleDateString()}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Politically exposed person (PEP)"
                      secondary={state.pep ? "Yes" : "No"}
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Sender Address
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 2 }}>
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="Address"
                    secondary={state.senderAddress}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="City" secondary={state.senderCity} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="State" secondary={state.senderState} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Zipcode"
                    secondary={state.senderZipCode}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Email" secondary={state.senderEmail} />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>

        {/* Identification */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Identification
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 2 }}>
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="Document Type"
                    secondary={state.identificationDocumentType}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Identification Document No."
                    secondary={state.identificationDocument}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="issue by country"
                    secondary={state.documentIssueByCountry}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="issue by state (if applicable)"
                    secondary={state.documentIssueByState}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Document Expiration Date"
                    secondary="2023-10-10"
                  />
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Box>
        </Box>
        {/* End Identification */}

        {/* Employer */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Sender’s Employer
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 2 }}>
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="Employer"
                    secondary={state.employerName}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Identification Document No."
                    secondary={state.identificationDocument}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Country"
                    secondary={state.employerCountry}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Address"
                    secondary={state.employerAddress}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="State (if applicable)"
                    secondary={state.employerState}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Zipcode (if applicable)"
                    secondary={state.employerZipCode}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Document Expiration Date"
                    secondary="2023-10-10"
                  />
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Box>
        </Box>
        {/* End Employer */}

        {/* Income */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Income
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 2 }}>
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="Source of Income"
                    secondary={state.incomeSource}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Income range"
                    secondary={state.earnings}
                  />
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Box>
        </Box>
        {/* End Income */}

        {/* Beneficiary */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Beneficiary
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ m: 2 }}>
              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="FullName"
                    secondary={state.beneficiaryFullName}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Gender"
                    secondary={state.beneficiaryGender}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Phone"
                    secondary={formatPhoneNumber(state.beneficiaryPhone)}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Address"
                    secondary={state.beneficiaryAddress}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="City"
                    secondary={state.beneficiaryCity}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Country"
                    secondary={state.beneficiaryCountry}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="State (if applicable)"
                    secondary={state.beneficiaryState}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="ZipCode (if applicable)"
                    secondary={state.beneficiaryZipCode}
                  />
                </ListItem>

                <Divider />
              </List>
            </Box>
          </Box>
        </Box>
        {/* End Beneficiary */}

        {/* Comments */}
        <Box
          gridColumn="span 3"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justyItems: "space-between",
              bgcolor: colors.primary[400],
              padding: 2,
            }}
          >
            <Box sx={{ my: 2, mx: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Transactional Behavior
                  </Typography>
                </Grid>
              </Grid>

              <List sx={style} component="nav" aria-label="mailbox folders">
                <ListItem>
                  <ListItemText
                    primary="Total Transactions QTY"
                    secondary={transactionsList.length}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Total Amount send $USD"
                    secondary={formatCurrency(
                      transactionsList.reduce(
                        (prev, transaction) => prev + transaction.amount,
                        0
                      )
                    )}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary="Transactions Avg"
                    secondary={formatCurrency(
                      transactionsList.reduce(
                        (prev, transaction) => prev + transaction.amount,
                        0
                      ) / transactionsList.length
                    )}
                  />
                </ListItem>

                <Divider />
              </List>
            </Box>

            {/* <Box sx={{ m: 2 }}>
              <Typography gutterBottom variant="body1">
                Risk Level
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip label="Low" />
                <Chip color="info" label="Medium" />
                <Chip label="High" />
              </Stack>
            </Box> */}
          </Box>
        </Box>
        {/* End Comments */}
      </Box>
      {/* All transactions */}
      <Box
        m="60px 00"
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Header
          title="Transactions"
          subtitle={`This list reflects all the transactions related to ${state.senderFullName}.`}
        />
        <div style={{ height: gridHeight, width: "100%" }}>
          <DataGrid
            checkboxSelection={false}
            rows={transactionsList}
            columns={columns}
            components={{ Toolbar: TransactionCustomToolbar }}
            disableSelectionOnClick
            getRowId={getRowId}
          />
        </div>
      </Box>

      {/* Group by recipients */}
      <Box
        m="60px 00"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Header
          title="Beneficiaries"
          subtitle={`A list containing all the beneficiaries related to ${state.senderFullName}.`}
        />
        <div style={{ height: beneficiariesHeight, width: "100%" }}>
          <DataGrid
            checkboxSelection={false}
            columns={columnsReceiver}
            components={{ Toolbar: TransactionCustomToolbar }}
            rows={distinctNames.map((receiver, index) => ({
              id: index + 1,
              receiver,
            }))}
            disableSelectionOnClick
          />
        </div>
      </Box>

      {/* Group by countries */}
      <Box
        m="60px 00"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Header
          title="Countries"
          subtitle={`All the countries related to ${state.senderFullName}`}
        />
        <div style={{ height: countriesHeight, width: "100%" }}>
          <DataGrid
            checkboxSelection={false}
            columns={columnsCountries}
            components={{ Toolbar: TransactionCustomToolbar }}
            rows={distinctCountries.map((country, index) => ({
              id: index + 1,
              country,
            }))}
            disableSelectionOnClick
          />
        </div>
      </Box>

      <Timeline position="alternate">
        {aggregationsList.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <TimelineItem key={index}>
                <Alert
                  a={item.ma}
                  b={item.ml}
                  color={colors}
                  comments={item.comments}
                />

                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="h4" component="span">
                      {formatDate(item.date)}
                    </Typography>
                    <CommentPopOver
                      color={colors}
                      transactionId={item._id}
                      user={auth().email}
                      comments={item.comments}
                      getAggregations={getAggregations}
                    />
                  </div>
                  <List component="nav" aria-label="mailbox folders">
                    {item.ml && (
                      <ListItem>
                        <ListItemText
                          primary="Multiple licenses aggregation"
                          secondary="(Positive)"
                        />
                      </ListItem>
                    )}
                    <Divider />
                    {item.ma && (
                      <ListItem>
                        <ListItemText
                          primary="Multiple branches aggregation"
                          secondary="(Positive)"
                        />
                      </ListItem>
                    )}
                  </List>
                  <Typography variant="h4" component="span">
                    Transactions
                  </Typography>
                  <List component="nav" aria-label="mailbox folders">
                    {item.transactions.transactions.map(
                      (transaction, index) => {
                        return (
                          <div key={index}>
                            <ListItem>
                              <ListItemText
                                primary={`${formatCurrency(
                                  transaction.amount
                                )} `}
                                secondary={`Sent through ${
                                  transaction.license
                                } to ${capitalize(
                                  transaction.receiver
                                )} in ${capitalize(transaction.country)}.`}
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        );
                      }
                    )}
                    {item.comments && (
                      <ListItem
                        style={{
                          alignContent: "start",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          textAlign: "left",
                        }}
                      >
                        <Typography variant="h4" component="span">
                          Notes
                        </Typography>
                        <Typography variant="body" component="p">
                          {item.comments.comment}
                        </Typography>
                        <Typography
                          variant="body"
                          component="i"
                          style={{ marginTop: 10 }}
                        >
                          Report generated by {item.comments.user}
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </TimelineContent>
              </TimelineItem>
            );
          }
          return (
            <TimelineItem key={index}>
              <Alert
                a={item.ma}
                b={item.ml}
                color={colors}
                comments={item.comments}
              />

              <TimelineContent sx={{ m: "auto 0" }} variant="body2">
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="h4" component="span">
                    {formatDate(item.date)}
                  </Typography>
                  <CommentPopOver
                    color={colors}
                    transactionId={item._id}
                    user={auth().email}
                    comments={item.comments}
                    getAggregations={getAggregations}
                  />
                </div>
                <List
                  component="nav"
                  aria-label="mailbox folders"
                  style={{
                    alignItems: "end",
                    display: "flex",
                    justifyItems: "end",
                    justifyContent: "end",
                  }}
                >
                  {item.ml && (
                    <ListItem
                      style={{
                        alignItems: "end",
                        display: "flex",
                        justifyItems: "end",
                        justifyContent: "end",
                        flexDirection: "column",
                      }}
                    >
                      <ListItemText
                        primary="Multiple licenses aggregation"
                        secondary="(Positive)"
                      />
                    </ListItem>
                  )}
                  <Divider />
                  {item.ma && (
                    <ListItem
                      style={{
                        alignItems: "end",
                        display: "flex",
                        justifyItems: "end",
                        justifyContent: "end",
                        flexDirection: "column",
                      }}
                    >
                      <ListItemText
                        primary="Multiple branches aggregation"
                        secondary="(Positive)"
                      />
                    </ListItem>
                  )}
                </List>
                <Typography variant="h4" component="span">
                  Transactions
                </Typography>
                <List
                  component="nav"
                  aria-label="mailbox folders"
                  style={{
                    alignContent: "end",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item.transactions.transactions.map((transaction, index) => {
                    return (
                      <div key={index}>
                        <ListItem
                          style={{
                            alignContent: "end",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            textAlign: "right",
                          }}
                        >
                          <ListItemText
                            primary={`${formatCurrency(transaction.amount)} `}
                            secondary={`Sent through ${
                              transaction.license
                            } to ${capitalize(
                              transaction.receiver
                            )} in ${capitalize(transaction.country)}.`}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
                  {item.comments && (
                    <ListItem
                      style={{
                        alignContent: "end",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        textAlign: "right",
                      }}
                    >
                      <Typography variant="h4" component="span">
                        Notes
                      </Typography>
                      <Typography variant="body" component="p">
                        {item.comments.comment}
                      </Typography>
                      <Typography
                        variant="body"
                        component="i"
                        style={{ marginTop: 10 }}
                      >
                        Report generated by {item.comments.user}
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default KycDetails;
