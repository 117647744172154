import React, { useEffect, useRef } from "react";
import { init } from "echarts";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const DonutChart = ({ rawTransactions, data = [], max }) => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const myChartRef = init(chartRef.current);
    const getLabelValue = (params) => {
      const arr = params.data.name.toLowerCase().split(" ");
      const words = arr.map((data) => data[0].toUpperCase() + data.slice(1));
      const country = words.join(" ");
      return `(${Number(
        (params.data.value / rawTransactions.length) * 100
      ).toFixed(2)}%) ${country}`;
    };

    // Define the options for the chart
    const options = {
      backgroundColor: "transparent",
      title: {
        left: "center",
        top: 0,
        textStyle: {
          color: "#ccc",
        },
      },
      tooltip: {
        trigger: "item",
      },
      // legend: {
      //   orient: "horizontal",
      //   bottom: 0,
      //   left: "right",
      //   textStyle: {
      //     color: colors.greenAccent[700],
      //   },
      // },
      visualMap: {
        show: false,
        min: 1,
        max: max * 3,
        inRange: {
          colorLightness: [0.2, 1],
        },
      },
      series: [
        {
          name: "Transactions From",
          type: "pie",
          radius: ["60%", "80%"],
          center: ["45%", "45%"],
          data: data.sort(function (a, b) {
            return a.value - b.value;
          }),
          //   roseType: "radius",
          label: {
            color: colors.primary[300],
            formatter: getLabelValue,
          },
          labelLine: {
            lineStyle: {
              color: colors.greenAccent[400],
            },
            smooth: 0.2,
            length: 10,
            length2: 20,
          },
          itemStyle: {
            color: colors.greenAccent[800],
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: function (idx) {
            return Math.random() * 200;
          },
        },
      ],
    };

    myChartRef.setOption(options);

    // add event listener to resize the chart when the window size changes
    window.addEventListener("resize", () => {
      myChartRef.resize();
    });
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div
        id="myChart"
        ref={chartRef}
        style={{ width: "100%", height: "650px" }}
      />
    </div>
  );
};

export default DonutChart;
