import {
    groupByCondition,
    calculateTransactionThresholds,
    getTopSendersAndReceivers,
    getMinAndMaxTransactions,
    getAggregatedTransactions,
    getTopSendersHighNoOfReceiversMoreThan10
} from "./index";

export const getPdfData = (rawTransactions, aggregation) => {
    try {
        let requestData = {};

        // Get Principal Matrics Data
        const principalMatricsData = groupByCondition(
            rawTransactions,
            "license",
            "frequency"
        );

        requestData.principalMatricsData = principalMatricsData;

        // Get Risk Consideration Data
        const riskConsiderationData =
            calculateTransactionThresholds(rawTransactions);
        requestData.riskConsiderationData = riskConsiderationData;

        // Fetch $999 Raw From The Risk Consideration Data
        const riskConsideration = riskConsiderationData[0];
        requestData.riskConsideration = riskConsideration;

        // Get Amount Spent By Countries Data
        const amountSpentByCountries = groupByCondition(
            rawTransactions,
            "country",
            "amount"
        );
        requestData.amountSpentByCountries = amountSpentByCountries;

        const top20Senders = getTopSendersAndReceivers(
            rawTransactions,
            "sender",
            20
        );
        requestData.top20Senders = top20Senders;
        // Get Top 20 Beneficiary Matrics
        const top20Beneficiary = getTopSendersAndReceivers(
            rawTransactions,
            "receiver",
            20
        );
        requestData.top20Beneficiary = top20Beneficiary;

        // Top Senders with Higher Number of Beneficiaries
        const topSendersHighNoOfReceivers = getTopSendersHighNoOfReceiversMoreThan10(
            rawTransactions,
            "sender",
            10,
            "partner"
        );
        requestData.topSendersHighNoOfReceivers = topSendersHighNoOfReceivers;

        // Single Transactions for more than US$3,000 and less than US$10,000
        const minAndMaxTransactionsData = getMinAndMaxTransactions(
            rawTransactions,
            3000,
            10000,
            20
        );
        requestData.minAndMaxTransactionsData = minAndMaxTransactionsData;

        // Single transactions for more than US$10,000
        const singleTransactionsMoreThan10kData = getAggregatedTransactions(
            aggregation,
            3000,
            10000,
            20
        );
        requestData.singleTransactionsMoreThan10kData =
            singleTransactionsMoreThan10kData;
        // Generate JSON File

        return requestData;
    } catch (error) {
        console.error("Error:::: ", error);
        // setIsDonwloadingPDF(false);
    }
};
