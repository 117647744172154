import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { countries } from "../../data/countries";
import { states } from "../../data/states";
import { formatDate, generateKey } from "../../utils";
import { useNavigate } from "react-router-dom";

import {
  aggregationKyc,
  get_kyc_by_id,
  insertKyc,
} from "../../data/Controller";
import { useParams } from "react-router-dom";

const Kyc = ({ branches, auth }) => {
  let navigate = useNavigate();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id, customer, kycId } = useParams();
  const [initialValues, setInitialValues] = useState({
    branch: "",
    aggregationId: id ? id : generateKey(),
    senderFullName: customer ? customer : "",
    senderGender: "",
    senderNationality: "",
    senderBirthDate: "",
    senderOccupation: "",
    senderEmail: "",
    senderPhone: "",
    senderAddress: "",
    senderCity: "",
    senderState: "",
    senderZipcode: "",
    identificationDocumentType: "",
    identificationDocument: "",
    documentExpirationDate: "",
    documentIssueByState: "",
    documentIssueByCountry: "",
    ssn: "",
    transactionType: "",
    beneficiaryFullName: "",
    beneficiaryGender: "",
    beneficiaryPhone: "",
    beneficiaryAddress: "",
    beneficiarycountry: "",
    beneficiaryCity: "",
    beneficiaryState: "N/A",
    beneficiaryZipCode: "N/A",
    employerName: "",
    employerCountry: "",
    employerAddress: "",
    employerCity: "",
    pep: false,
    employerState: "N/A",
    employerZipCode: "N/A",
    comments: "",
    earnings: "",
    incomeSource: "",
    version: "",
  });

  const update = (id) => {
    aggregationKyc(customer, true, id).then(() =>
      navigate("/know-your-customer-list")
    );
  };

  const getRecord = () => {
    const record = get_kyc_by_id(id);
    record.then((d) => {
      const record = d[d.length - 1];
      record.version = d.length;
      setInitialValues(record);
    });
  };

  const handleFormSubmit = (values) => {
    const user = auth();
    values.agent_id = user.agent_id;

    values.createdDate = new Date();
    values.createdBy = user.email;
    values.documentExpirationDate = new Date(values.documentExpirationDate);
    values.senderBirthDate = new Date(values.senderBirthDate);

    // Create new record with a different Id
    if (kycId) delete values._id;
    const result = insertKyc(values);

    result.then((d) => {
      const id = d.data.insertedIds[0];
      return update(id);
    });
  };

  useEffect(() => {
    if (kycId) getRecord();
  }, []);

  return (
    <Box m="20px">
      <Header
        title={
          kycId ? `Current Version ${initialValues.version}` : "Create Record"
        }
        subtitle="Profiling customer"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(6, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
              }}
            >
              <Box gridColumn="span 6" gridRow={1}>
                <FormControl>
                  <InputLabel id="branch">Select Branch</InputLabel>
                  <Select
                    labelId="branch"
                    name="branch"
                    value={values.branch}
                    label="Branch"
                    autoWidth
                    style={{ minWidth: 200 }}
                    onChange={handleChange}
                    error={!!touched.branch && !!errors.branch}
                  >
                    {branches.map((item, key) => (
                      <MenuItem value={item} key={key}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ m: "24px 0 5px 0", gridColumn: "span 6" }}
              >
                Sender Personal Information
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderFullName}
                name="senderFullName"
                error={!!touched.senderFullName && !!errors.senderFullName}
                helperText={touched.senderFullName && errors.senderFullName}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="gender">Select Gender</InputLabel>
                <Select
                  labelId="gender"
                  name="senderGender"
                  value={values.senderGender}
                  label="Gender"
                  autoWidth
                  style={{ minWidth: 200 }}
                  // onChange={(e) => setSenderGender(e.target.value)}
                  onChange={handleChange}
                  error={!!touched.senderGender && !!errors.senderGender}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nationality"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderNationality}
                name="senderNationality"
                error={
                  !!touched.senderNationality && !!errors.senderNationality
                }
                helperText={
                  touched.senderNationality && errors.senderNationality
                }
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Occupation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderOccupation}
                name="senderOccupation"
                error={!!touched.senderOccupation && !!errors.senderOccupation}
                helperText={touched.senderOccupation && errors.senderOccupation}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="date Of Birth"
                onBlur={handleBlur}
                // onChange={(e) => setBirthDate(e.target.value)}
                onChange={handleChange}
                value={values.senderBirthDate}
                InputLabelProps={{
                  shrink: true,
                }}
                name="senderBirthDate"
                error={!!touched.senderBirthDate && !!errors.senderBirthDate}
                helperText={touched.senderBirthDate && errors.senderBirthDate}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderEmail}
                name="senderEmail"
                error={!!touched.senderEmail && !!errors.senderEmail}
                helperText={touched.senderEmail && errors.senderEmail}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderPhone}
                name="senderPhone"
                error={!!touched.senderPhone && !!errors.senderPhone}
                helperText={touched.senderPhone && errors.senderPhone}
                sx={{ gridColumn: "span 2" }}
              />
              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Sender's physical address
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderAddress}
                name="senderAddress"
                error={!!touched.senderAddress && !!errors.senderAddress}
                helperText={touched.senderAddress && errors.senderAddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderCity}
                name="senderCity"
                error={!!touched.senderCity && !!errors.senderCity}
                helperText={touched.senderCity && errors.senderCity}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="senderState">State</InputLabel>
                <Select
                  labelId="senderState"
                  name="senderState"
                  value={values.senderState}
                  label="State"
                  autoWidth
                  onChange={handleChange}
                  error={!!touched.senderState && !!errors.senderState}
                >
                  {states.map(({ name, code }) => (
                    <MenuItem value={code} key={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.senderZipcode}
                name="senderZipcode"
                error={!!touched.senderZipcode && !!errors.senderZipcode}
                helperText={touched.senderZipcode && errors.senderZipcode}
                sx={{ gridColumn: "span 1" }}
              />

              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Sender's identification document
              </Typography>
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="identificationDocumentType">
                  Select ID Type
                </InputLabel>
                <Select
                  labelId="identificationDocumentType"
                  name="identificationDocumentType"
                  value={values.identificationDocumentType}
                  label="identificationDocumentType"
                  autoWidth
                  onChange={handleChange}
                  error={
                    !!touched.identificationDocumentType &&
                    !!errors.identificationDocumentType
                  }
                >
                  <MenuItem value="Driver license">Driver license</MenuItem>
                  <MenuItem value="Voting ID">Voting ID</MenuItem>
                  <MenuItem value="state issue ID">state issue ID</MenuItem>
                  <MenuItem value="Resident Alien card (Green card)">
                    Resident Alien card (Green card)
                  </MenuItem>
                  <MenuItem value="Military ID">Military ID</MenuItem>
                  <MenuItem value="passport">passport</MenuItem>
                  <MenuItem value="Consulate ID">Consulate ID</MenuItem>
                  <MenuItem value="Work Permit">Work Permit</MenuItem>
                  <MenuItem value="Cedula">Cedula</MenuItem>
                  <MenuItem value="other / Describe">other / Describe</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="ID Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.identificationDocument}
                name="identificationDocument"
                error={
                  !!touched.identificationDocument &&
                  !!errors.identificationDocument
                }
                helperText={
                  touched.identificationDocument &&
                  errors.identificationDocument
                }
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="documentExpirationDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.documentExpirationDate}
                InputLabelProps={{
                  shrink: true,
                }}
                name="documentExpirationDate"
                error={
                  !!touched.documentExpirationDate &&
                  !!errors.documentExpirationDate
                }
                helperText={
                  touched.documentExpirationDate &&
                  errors.documentExpirationDate
                }
                sx={{ gridColumn: "span 1" }}
              />
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="country">Select Country</InputLabel>
                <Select
                  labelId="documentIssueByCountry"
                  name="documentIssueByCountry"
                  value={values.documentIssueByCountry}
                  label="Issue By Country"
                  autoWidth
                  onChange={handleChange}
                  error={
                    !!touched.documentIssueByCountry &&
                    !!errors.documentIssueByCountry
                  }
                >
                  {countries.map(({ name, code }) => (
                    <MenuItem value={code} key={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {values.employerCountry === "US" && (
                <FormControl sx={{ gridColumn: "span 1" }}>
                  <InputLabel id="documentIssueByState">
                    Document issued state
                  </InputLabel>
                  <Select
                    labelId="documentIssueByState"
                    name="documentIssueByState"
                    value={values.documentIssueByState}
                    label="Issue by State"
                    autoWidth
                    style={{ minWidth: 200 }}
                    onChange={handleChange}
                  >
                    {states.map(({ name, code }) => (
                      <MenuItem value={code} key={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Social Security No."
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ssn}
                name="ssn"
                error={!!touched.ssn && !!errors.ssn}
                helperText={touched.ssn && errors.ssn}
                sx={{ gridColumn: "span 1" }}
              />
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ m: "56px 0 5px 0", gridColumn: "span 6" }}
              >
                Beneficiary Personal Information
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.beneficiaryFullName}
                name="beneficiaryFullName"
                error={
                  !!touched.beneficiaryFullName && !!errors.beneficiaryFullName
                }
                helperText={
                  touched.beneficiaryFullName && errors.beneficiaryFullName
                }
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="gender">Select Gender</InputLabel>
                <Select
                  labelId="gender"
                  name="beneficiaryGender"
                  value={values.beneficiaryGender}
                  label="Gender"
                  autoWidth
                  style={{ minWidth: 200 }}
                  onChange={handleChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.beneficiaryPhone}
                name="beneficiaryPhone"
                error={!!touched.beneficiaryPhone && !!errors.beneficiaryPhone}
                helperText={touched.beneficiaryPhone && errors.beneficiaryPhone}
                sx={{ gridColumn: "span 3" }}
              />

              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Beneficiary's physical address
              </Typography>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.beneficiaryAddress}
                name="beneficiaryAddress"
                error={
                  !!touched.beneficiaryAddress && !!errors.beneficiaryAddress
                }
                helperText={
                  touched.beneficiaryAddress && errors.beneficiaryAddress
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.beneficiaryCity}
                name="beneficiaryCity"
                error={!!touched.beneficiaryCity && !!errors.beneficiaryCity}
                helperText={touched.beneficiaryCity && errors.beneficiaryCity}
                sx={{ gridColumn: "span 1" }}
              />
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="beneficiarycountry">Select Country</InputLabel>
                <Select
                  labelId="country"
                  name="beneficiarycountry"
                  value={values.beneficiarycountry}
                  label="country"
                  autoWidth
                  onChange={handleChange}
                >
                  {countries.map(({ name, code }) => (
                    <MenuItem value={code} key={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {values.beneficiarycountry === "US" && (
                <FormControl sx={{ gridColumn: "span 1" }}>
                  <InputLabel id="documentIssueByState">State</InputLabel>
                  <Select
                    labelId="beneficiaryState"
                    name="beneficiaryState"
                    value={values.beneficiaryState}
                    label="beneficiary State"
                    autoWidth
                    onChange={handleChange}
                  >
                    {states.map(({ name, code }) => (
                      <MenuItem value={code} key={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {values.beneficiarycountry === "US" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Zip Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.beneficiaryZipCode}
                  name="beneficiaryZipCode"
                  error={
                    !!touched.beneficiaryZipCode && !!errors.beneficiaryZipCode
                  }
                  helperText={
                    touched.beneficiaryZipCode && errors.beneficiaryZipCode
                  }
                  sx={{ gridColumn: "span 1" }}
                />
              )}

              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ m: "56px 0 0 0", gridColumn: "span 6" }}
              >
                About the transaction
              </Typography>

              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="transactionType">Transaction Type</InputLabel>
                <Select
                  labelId="transactionType"
                  name="transactionType"
                  value={values.transactionType}
                  label="Transaction Type"
                  autoWidth
                  style={{ minWidth: 200 }}
                  onChange={handleChange}
                  error={!!touched.transactionType && !!errors.transactionType}
                >
                  <MenuItem value="Money transfer">Money transfer</MenuItem>
                  <MenuItem value="Check cashing">Check cashing</MenuItem>
                  <MenuItem value="Money Order">Money Order</MenuItem>
                  <MenuItem value="Bill Payment">Bill Payment</MenuItem>
                  <MenuItem value="Currency exchange">
                    Currency exchange
                  </MenuItem>
                  <MenuItem value="Store value card">Store value card</MenuItem>
                </Select>
                <Typography
                  variant="body2"
                  sx={{ marginTop: 1, gridColumn: "span 6" }}
                >
                  What type of transaction triggered this KYC Form?
                </Typography>
              </FormControl>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ m: "56px 0 5px 0", gridColumn: "span 6" }}
              >
                Enhanced Due Diligence (EDD)
              </Typography>
              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Employer Information
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Employer name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerName}
                name="employerName"
                error={!!touched.employerName && !!errors.employerName}
                helperText={touched.employerName && errors.employerName}
                sx={{ gridColumn: "span 2" }}
              />
              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                employer's physical address
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerAddress}
                name="employerAddress"
                error={!!touched.employerAddress && !!errors.employerAddress}
                helperText={touched.employerAddress && errors.employerAddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerCity}
                name="employerCity"
                error={!!touched.employerCity && !!errors.employerCity}
                helperText={touched.employerCity && errors.employerCity}
                sx={{ gridColumn: "span 1" }}
              />
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="employerCountry">Select Country</InputLabel>
                <Select
                  labelId="employerCountry"
                  name="employerCountry"
                  value={values.employerCountry}
                  label="Country"
                  autoWidth
                  onChange={handleChange}
                >
                  {countries.map(({ name, code }) => (
                    <MenuItem value={code} key={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {values.employerCountry === "US" && (
                <FormControl sx={{ gridColumn: "span 1" }}>
                  <InputLabel id="employerState">State</InputLabel>
                  <Select
                    labelId="employerState"
                    name="employerState"
                    value={values.employerState}
                    label="State"
                    autoWidth
                    onChange={handleChange}
                  >
                    {states.map(({ name, code }) => (
                      <MenuItem value={code} key={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {values.employerCountry === "US" && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Zip Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employerZipCode}
                  name="employerZipCode"
                  error={!!touched.employerZipCode && !!errors.employerZipCode}
                  helperText={touched.employerZipCode && errors.employerZipCode}
                  sx={{ gridColumn: "span 1" }}
                />
              )}
              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Is the customer a Politically Exposed Person (PEP)?
              </Typography>
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="pep">Pep</InputLabel>
                <Select
                  labelId="pep"
                  name="pep"
                  value={values.pep}
                  label="pep"
                  autoWidth
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              <Typography
                variant="h5"
                sx={{ m: "16px 0 5px 0", gridColumn: "span 6" }}
              >
                Information about income
              </Typography>
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="incomeSource">Source of Income</InputLabel>
                <Select
                  labelId="incomeSource"
                  name="incomeSource"
                  value={values.incomeSource}
                  label="income Source"
                  autoWidth
                  onChange={handleChange}
                >
                  <MenuItem value="Annual Income">
                    Annual Income (Fulltime)
                  </MenuItem>
                  <MenuItem value="Business Owner">Business Owner</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 1" }}>
                <InputLabel id="earning">Earnings Range</InputLabel>
                <Select
                  labelId="earning"
                  name="earnings"
                  value={values.earnings}
                  label="Earnings"
                  autoWidth
                  onChange={handleChange}
                >
                  <MenuItem value="$0-$50k">$0-50k</MenuItem>
                  <MenuItem value="$50k-$100k">$50k-$100k</MenuItem>
                  <MenuItem value="$100k-$200k">$100k-$200k</MenuItem>
                  <MenuItem value="$200k-$500k">$200k-$500k</MenuItem>
                  <MenuItem value="+$500k">+$500k</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ m: "56px 0 5px 0", gridColumn: "span 6" }}
            >
              Notes
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Comments"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.comments}
              name="comments"
              error={!!touched.comments && !!errors.comments}
              helperText={touched.comments && errors.comments}
              sx={{ gridColumn: "span 6", gridRow: "span 2" }}
              multiline
            />

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                {kycId ? "Update Record" : "Create Record"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const strRegExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
const allCharsRegExp = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\r\n ]+$/;

const checkoutSchema = yup.object().shape({
  senderFullName: yup
    .string()
    .matches(strRegExp, "Fullname is not valid")
    .required("required"),
  branch: yup.string(),
  senderEmail: yup.string().email("invalid email"),
  senderGender: yup.string().required("required"),
  senderBirthDate: yup.string().required("required"),
  senderPhone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  senderNationality: yup
    .string()
    .matches(strRegExp, "Nationality is not valid")
    .required("required"),
  senderOccupation: yup
    .string()
    .matches(strRegExp, "Occupation is not valid")
    .required("required"),
  senderAddress: yup.string().required("required"),
  senderCity: yup.string().required("required"),
  senderState: yup.string(),

  documentIssueByCountry: yup.string().required("required"),
  identificationDocumentType: yup.string().required("required"),
  identificationDocument: yup.string().required("required"),
  documentExpirationDate: yup.string().required("required"),

  beneficiaryFullName: yup.string(),
  beneficiaryPhone: yup.string(),
  beneficiaryAddress: yup.string(),
  beneficiaryCity: yup.string(),
  beneficiaryZipCode: yup.string(),

  transactionType: yup.string().required("required"),

  employerAddress: yup.string(),
  beneficiaryCity: yup.string(),
  employerZipCode: yup.number(),

  comments: yup
    .string()
    .matches(allCharsRegExp, "There is something with your comment")
    .required("required"),

  ssn: yup.number(),
});

export default Kyc;
