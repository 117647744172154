import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { aggregationEdit } from "../data/Controller";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

const CommentPopOver = ({
  color,
  transactionId,
  user,
  comments,
  getAggregations,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const body = {
      comment,
      user,
      date: new Date(),
    };

    const results = aggregationEdit(transactionId, body);
    results.then(() => getAggregations());
    handleClose();
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    setComment("");
    const body = false;

    aggregationEdit(transactionId, body);
    getAggregations();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleComment = (str) => {
    setComment(str.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (comments?.comment) {
      setComment(comments.comment);
    }
  }, []);

  return (
    <>
      <Button
        size="small"
        style={{
          backgroundColor: comment
            ? color.greenAccent[500]
            : color.redAccent[500],
          borderRadius: 5,
          color: comment ? "black" : "white",
          textDecoration: "none",
          padding: "5px 10px",
          display: "inline-block",
          marginTop: "10px",
        }}
        onClick={handleClick}
      >
        {comment ? "Edit Comment" : "Resolve Alert"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ padding: 50 }}
      >
        <form
          style={{ width: "100%", padding: 20 }}
          onSubmit={handleFormSubmit}
        >
          <Typography variant="h3">Notes:</Typography>
          <Typography sx={{ p: 2 }}>
            To mitigate this alert you need to provide context about
            <br />
            the motives behind this transaction
          </Typography>
          <TextField
            label="Comments"
            multiline
            rows={4}
            onChange={handleComment}
            value={comment}
            variant="standard"
            style={{ width: "100%" }}
          />
          <Button
            size="small"
            style={{
              backgroundColor: color.greenAccent[500],
              borderRadius: 5,
              color: "black",
              textDecoration: "none",
              padding: "5px 10px",
              display: "inline-block",
              marginTop: "10px",
            }}
            type="submit"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            Save
          </Button>
          <Button
            size="small"
            style={{
              backgroundColor: color.redAccent[500],
              borderRadius: 5,
              color: "#FFF",
              textDecoration: "none",
              padding: "5px 10px",
              display: "inline-block",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            aria-describedby={id}
            variant="contained"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </form>
      </Popover>
    </>
  );
};

export default CommentPopOver;
