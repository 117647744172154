import { Box, Button, useTheme, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { formatDate } from "../../utils";
import { getKycList } from "../../data/Controller";
import { Link, useSearchParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const KycList = ({ auth }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [range, setRange] = useState([]); // eslint-disable-line no-unused-vars

  const columns = [
    { field: "branch", headerName: "Branch", flex: 1, hide: true },
    { field: "senderFullName", headerName: "Sender's Name", flex: 1 },
    {
      field: "senderGender",
      headerName: "Sender's Gender",
      flex: 1,
      hide: true,
    },
    {
      field: "createdDate",
      headerName: "Creation Date",
      flex: 1,
      hide: false,
      type: "date",
      renderCell: (params) => formatDate(params.row.createdDate),
    },
    {
      field: "senderNationality",
      headerName: "Nationality",
      flex: 1,
      hide: true,
    },
    {
      field: "senderBirthDate",
      headerName: "Sender's Birthday",
      flex: 1,
      hide: true,
    },
    {
      field: "senderOccupation",
      headerName: "Sender's Occupation",
      flex: 1,
      hide: true,
    },
    { field: "senderEmail", headerName: "Sender's Email", flex: 1 },
    { field: "senderPhone", headerName: "Sender's Phone", flex: 1 },
    {
      field: "senderAddress",
      headerName: "Sender's Address",
      flex: 1,
      hide: true,
    },
    { field: "senderCity", headerName: "Sender's City", flex: 1 },
    { field: "senderState", headerName: "Sender's State", flex: 1 },
    { field: "senderZipcode", headerName: "Sender's Zipcode", flex: 1 },
    {
      field: "identificationDocumentType",
      headerName: "Identification Type",
      flex: 1,
      hide: true,
    },
    {
      field: "identificationDocument",
      headerName: "Identification No.",
      flex: 1,
      hide: true,
    },
    {
      field: "documentExpirationDate",
      headerName: "Identification Exp",
      flex: 1,
      hide: true,
    },
    {
      field: "documentIssueByCountry",
      headerName: "Identification country",
      flex: 1,
      hide: true,
    },
    { field: "ssn", headerName: "Social Security No,", flex: 1, hide: true },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryFullName",
      headerName: "Beneficiary FullName",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryGender",
      headerName: "Beneficiary Gender",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryPhone",
      headerName: "Beneficiary Phone",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryAddress",
      headerName: "Beneficiary Address",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryCity",
      headerName: "Beneficiary City",
      flex: 1,
      hide: true,
    },
    {
      field: "beneficiaryZipCode",
      headerName: "Beneficiary Zip Code",
      flex: 1,
      hide: true,
    },
    { field: "employerName", headerName: "Employer", flex: 1, hide: true },
    {
      field: "employerAddress",
      headerName: "Employer's Address",
      flex: 1,
      hide: true,
    },
    {
      field: "employerCity",
      headerName: "Employer's City",
      flex: 1,
      hide: true,
    },
    {
      field: "employerCountry",
      headerName: "Employer's Country",
      flex: 1,
      hide: true,
    },
    {
      field: "employerZipCode",
      headerName: "Employer's Name",
      flex: 1,
      hide: true,
    },
    { field: "comments", headerName: "Comments", flex: 1, hide: true },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (data) => {
        const queryParams = new URLSearchParams();
        for (const key in data.row) {
          queryParams.append(key, data.row[key]);
        }
        return (
          <Link
            to={`/know-your-customer-details?${queryParams.toString()}`}
            // to={`/know-your-customer-details?sender=${data.row.senderFullName}`}
            size="small"
            style={{
              backgroundColor: colors.greenAccent[500],
              borderRadius: 5,
              color: "black",
              textDecoration: "none",
              padding: "5px 10px",
            }}
          >
            Load details
          </Link>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <GridToolbar />
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const getKyc = () => {
      const user = auth();

      const result = getKycList(user.agent_id);
      result.then((data) => {
        data.forEach((row, index) => {
          if (!row.id) {
            row.id = row._id;
          }
        });
        setRange(data);
      });
    };

    getKyc();
  }, []);
  const getRowId = (row) => row.id;
  return (
    <Box m="20px">
      <Header
        title="Know Your Customer (KYC)"
        subtitle="Verify the identity and other relevant information of your customers as part of your due diligence and compliance with legal and regulatory requirements."
      />
      <Link
        to={`/know-your-customer/`}
        size="small"
        style={{
          backgroundColor: colors.greenAccent[500],
          border: `thin solid ${colors.greenAccent[500]}`,
          borderRadius: 5,
          color: "black",
          textDecoration: "none",
          padding: "5px 10px",
          display: "inline-block",
        }}
      >
        Create KYC
      </Link>
      <Box
        m="40px 00"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            width: 1,
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection={false}
          rows={range}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "createdDate", sort: "desc" }],
            },
          }}
          // getRowId={getRowId}
        />
      </Box>
    </Box>
  );
};
