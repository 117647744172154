import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { formatCurrency, groupAmountsBy, capitalize } from "../../utils";
import Header from "../../components/Header";

export const CountriesDashboardTable = ({ rawTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tableData = groupAmountsBy(rawTransactions, "country");

  const columns = [
    {
      field: "name",
      headerName: "Country",

      // minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Typography>{capitalize(params.row.name)}</Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Total Amount Sent",
      align: "center",
      headerAlign: "center",
      // minWidth: 150,

      renderCell: (params) => (
        <Typography>{formatCurrency(params.row.amount)}</Typography>
      ),
    },
    {
      field: "frequency",
      headerName: "Number of Trx",
      align: "center",
      headerAlign: "center",
      // minWidth: 150,

      renderCell: (params) => (
        <Typography>{params.row.frequency.toLocaleString("en-US")}</Typography>
      ),
    },
    {
      field: "avg",
      headerName: "Transaction Amount Avg",
      // minWidth: 150,
      align: "right",
      headerAlign: "right",

      renderCell: (params) => (
        <Typography>{formatCurrency(Number(params.row.avg))}</Typography>
      ),
    },
  ];

  return (
    <Box>
      <Box m="20px">
        <Header
          title=""
          subtitle="Here you will find information related to the total dollar amount, the total number of transactions sent, and most importantly, the dollar amount average per transaction to each one of these countries."
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>
        <Box
          m="20px 00"
          height="405px"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={tableData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Box>
  );
};
