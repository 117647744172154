import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
  },
  {
    country: "AE",
    "hot dog": 55,
  },
  {
    country: "AF",
    "hot dog": 109,
  },
  {
    country: "AG",
    "hot dog": 133,
  },
  {
    country: "AI",
    "hot dog": 81,
  },
  {
    country: "AL",
    "hot dog": 66,
  },
  {
    country: "AM",
    "hot dog": 80,
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "japan",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];

export const mockAggregationData = [
  {
    id: 0,
    name: "ABRAAO",
    transactions: {
      aggregation: 4020,
      transactionAvg: 4020,
      transactions: [
        {
          sender: "ABRAAO, MARTINS DE GUSMAO",
          receiver: "ABRAAO, MARTINS DE GUSMAO",
          date: "2022-10-30",
          amount: 2010,
        },
        {
          sender: "ABRAAO, MARTINS DE GUSMAO",
          receiver: "ENOCK, GUSMAO",
          date: "2022-10-30",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 1,
    name: "ADILSON",
    transactions: {
      aggregation: 7120,
      transactionAvg: 7120,
      transactions: [
        {
          sender: "ADILSON, DE CASTRO SILVA",
          receiver: "MATHEUS, DE CASTRO SILVA",
          date: "2022-03-22",
          amount: 7010,
        },
        {
          sender: "ADILSON, DE CASTRO SILVA",
          receiver: "BRUNA, ROCHA BOMTEMPO",
          date: "2022-03-22",
          amount: 110,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 2,
    name: "ADRIANA MARIA",
    transactions: {
      aggregation: 3202.11,
      transactionAvg: 3202.11,
      transactions: [
        {
          sender: "ADRIANA MARIA, DA SILVA",
          receiver: "MARIA ZILDA, SILVA",
          date: "2022-08-23",
          amount: 2992.11,
        },
        {
          sender: "ADRIANA MARIA, DA SILVA",
          receiver: "MARIA DAS GRACAS, SILVA",
          date: "2022-08-23",
          amount: 210,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 3,
    name: "ALINE",
    transactions: {
      aggregation: 3072.61,
      transactionAvg: 3072.61,
      transactions: [
        {
          sender: "ALINE, MENDES BORGES PAIVA",
          receiver: "JOSE, LUIZ BORGES FILHO",
          date: "2022-05-18",
          amount: 2067.61,
        },
        {
          sender: "ALINE, MENDES BORGES PAIVA",
          receiver: "ANDRE WILSON ALVES, DE PAIVA",
          date: "2022-05-18",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 4,
    name: "ALLAN HILCON",
    transactions: {
      aggregation: 4933.24,
      transactionAvg: 4933.24,
      transactions: [
        {
          sender: "ALLAN HILCON, ALVES",
          receiver: "NELSON DOS SANTOS, NUNES",
          date: "2022-04-11",
          amount: 1886.36,
        },
        {
          sender: "ALLAN HILCON, ALVES",
          receiver: "ELYETTY, CONCEICAO SILVA",
          date: "2022-04-11",
          amount: 3046.88,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 5,
    name: "ANDRE L",
    transactions: {
      aggregation: 4012.02,
      transactionAvg: 4012.02,
      transactions: [
        {
          sender: "ANDRE L, DA SILVA",
          receiver: "SOLANGE ETERNA, DE ALMEIDA",
          date: "2023-01-31",
          amount: 3800,
        },
        {
          sender: "ANDRE L, DA SILVA",
          receiver: "SOLANGE ETERNA, DE ALMEIDA",
          date: "2023-01-31",
          amount: 212.02,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 6,
    name: "ANGELI D",
    transactions: {
      aggregation: 3512.01,
      transactionAvg: 3512.01,
      transactions: [
        {
          sender: "ANGELI D, BARROS LUCAS",
          receiver: "ANGELI, DAS GRACAS BARROS LUCAS",
          date: "2022-06-17",
          amount: 2005,
        },
        {
          sender: "ANGELI D, BARROS LUCAS",
          receiver: "MARIA ALICE, BOSQUIROLLI",
          date: "2022-06-17",
          amount: 1507.01,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 7,
    name: "ANGELO",
    transactions: {
      aggregation: 3482.29,
      transactionAvg: 1741.145,
      transactions: [
        {
          sender: "ANGELO, DIAS ARAUJO",
          receiver: "JOAO BATISTA, DE SOUSA JUNIOR",
          date: "2022-11-07",
          amount: 167.06,
        },
        {
          sender: "ANGELO, DIAS ARAUJO",
          receiver: "DIEGO CAMARGOS, DE OLIVEIRA",
          date: "2022-11-07",
          amount: 805.23,
        },
        {
          sender: "ANGELO, DIAS ARAUJO",
          receiver: "ANGELO, DIAS ARAUJO",
          date: "2022-11-07",
          amount: 2510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 8,
    name: "ANTONIA NAUSIRENE",
    transactions: {
      aggregation: 5125,
      transactionAvg: 5125,
      transactions: [
        {
          sender: "ANTONIA NAUSIRENE, ARAUJO SILVA BRITO",
          receiver: "MANOEL, SOUSA PEREIRA",
          date: "2022-07-05",
          amount: 5010,
        },
        {
          sender: "ANTONIA NAUSIRENE, ARAUJO SILVA BRITO",
          receiver: "ROSINALDO, BISPO SOUSA",
          date: "2022-07-05",
          amount: 115,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 9,
    name: "ARNALDO",
    transactions: {
      aggregation: 3349.02,
      transactionAvg: 837.255,
      transactions: [
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "OSNI, COTA VALADAO",
          date: "2022-06-20",
          amount: 168.42,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "MARIANE LUIZA, RODRIGUES",
          date: "2022-06-20",
          amount: 770.4,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "JOSE ANTONIO, BORGES JUNIOR",
          date: "2022-06-20",
          amount: 128.81,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "RAISA ALOIS, MARTINS FERREIRA",
          date: "2022-06-20",
          amount: 1796.14,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "LUCIANO, DINIZ FERREIRA",
          date: "2022-06-20",
          amount: 485.25,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 10,
    name: "ARNALDO",
    transactions: {
      aggregation: 4322.58,
      transactionAvg: 540.3225,
      transactions: [
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "BRENDHA, K M CUNHA",
          date: "2023-02-11",
          amount: 243.46,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "RONALDO, P BORGES",
          date: "2023-02-11",
          amount: 739.57,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "JOSE ANTONIO, BORGES JUNIOR",
          date: "2023-02-11",
          amount: 412.72,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "MN, MATERIAIS DE CONSTRUCAO LTDA",
          date: "2023-02-11",
          amount: 520.8,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "PAULO CESAR, GOMES SILVA",
          date: "2023-02-11",
          amount: 325.18,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "MARINA DOS, SANTOS E SOUZA",
          date: "2023-02-11",
          amount: 379.65,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "CHARLES, CESAR DA CRUZ",
          date: "2023-02-11",
          amount: 204.55,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "JAIR, CEZAR ANDRADE",
          date: "2023-02-11",
          amount: 253.19,
        },
        {
          sender: "ARNALDO, SILVERIO SALES",
          receiver: "PEDRO CARLOS, DE ARAUJO",
          date: "2023-02-11",
          amount: 1243.46,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 11,
    name: "BERNARDETE TERESINHA",
    transactions: {
      aggregation: 3220,
      transactionAvg: 3220,
      transactions: [
        {
          sender: "BERNARDETE TERESINHA, CANOFF",
          receiver: "RAFAEL, BECKER",
          date: "2022-12-15",
          amount: 210,
        },
        {
          sender: "BERNARDETE TERESINHA, CANOFF",
          receiver: "BERNARDETE TERESINHA, CANOFF",
          date: "2022-12-15",
          amount: 3010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 12,
    name: "BONIFACIO",
    transactions: {
      aggregation: 3391.29,
      transactionAvg: 3391.29,
      transactions: [
        {
          sender: "BONIFACIO, ALVES DE SOUZA",
          receiver: "REGINALDO, REIS SILVA",
          date: "2022-06-20",
          amount: 2881.29,
        },
        {
          sender: "BONIFACIO, ALVES DE SOUZA",
          receiver: "REGINALDO A, SOUSA",
          date: "2022-06-20",
          amount: 510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 13,
    name: "BONIFACIO",
    transactions: {
      aggregation: 4211.62,
      transactionAvg: 4211.62,
      transactions: [
        {
          sender: "BONIFACIO, ALVES DE SOUZA",
          receiver: "REGINALDO, REIS SILVA",
          date: "2022-08-08",
          amount: 4002.02,
        },
        {
          sender: "BONIFACIO, ALVES DE SOUZA",
          receiver: "ELIANA MAGDA, LOPES",
          date: "2022-08-08",
          amount: 209.6,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 14,
    name: "BRUNA LUIZA",
    transactions: {
      aggregation: 6020,
      transactionAvg: 6020,
      transactions: [
        {
          sender: "BRUNA LUIZA, MELO ALVES",
          receiver: "BRUNA LUIZA, DE MELO ALVES",
          date: "2022-09-18",
          amount: 3010,
        },
        {
          sender: "BRUNA LUIZA, MELO ALVES",
          receiver: "BRUNA LUIZA, DE MELO ALVES",
          date: "2022-09-18",
          amount: 3010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 15,
    name: "CAMILA NATIELE",
    transactions: {
      aggregation: 4988.52,
      transactionAvg: 4988.52,
      transactions: [
        {
          sender: "CAMILA NATIELE, SILVA CASTRO",
          receiver: "ELIAS, SAULOS DE FARIA",
          date: "2022-03-31",
          amount: 4078.52,
        },
        {
          sender: "CAMILA NATIELE, SILVA CASTRO",
          receiver: "LUCAS VINICIUS, P CASTRO",
          date: "2022-03-31",
          amount: 910,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 16,
    name: "CAMILA NATIELE",
    transactions: {
      aggregation: 3210,
      transactionAvg: 3210,
      transactions: [
        {
          sender: "CAMILA NATIELE, SILVA CASTRO",
          receiver: "CAMILA NATIELE SILVA, CASTRO",
          date: "2022-06-01",
          amount: 2605,
        },
        {
          sender: "CAMILA NATIELE, SILVA CASTRO",
          receiver: "LUCAS VINICIUS, P CASTRO",
          date: "2022-06-01",
          amount: 605,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 17,
    name: "CAMILA",
    transactions: {
      aggregation: 4160,
      transactionAvg: 4160,
      transactions: [
        {
          sender: "CAMILA, CRUZ GAUDENCIO DE ABREU",
          receiver: "SILVANIA CRUZ, GAUDENCIO",
          date: "2022-05-10",
          amount: 3005,
        },
        {
          sender: "CAMILA, CRUZ GAUDENCIO DE ABREU",
          receiver: "LAIS, GAUDENCIO",
          date: "2022-05-10",
          amount: 1155,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 18,
    name: "CARLOS FERREIRA",
    transactions: {
      aggregation: 4410,
      transactionAvg: 4410,
      transactions: [
        {
          sender: "CARLOS FERREIRA, PAIVA",
          receiver: "MARIA, FLAVIANA PEREIRA DA SILVA COSTA",
          date: "2022-10-05",
          amount: 3405,
        },
        {
          sender: "CARLOS FERREIRA, PAIVA",
          receiver: "JOSUEL LIMA, MARTINS",
          date: "2022-10-05",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 19,
    name: "CARLOS ROBERTO",
    transactions: {
      aggregation: 5020,
      transactionAvg: 5020,
      transactions: [
        {
          sender: "CARLOS ROBERTO, DE ANDRADE",
          receiver: "CARLOS ROBERTO, DE ANDRADE",
          date: "2023-02-25",
          amount: 4010,
        },
        {
          sender: "CARLOS ROBERTO, DE ANDRADE",
          receiver: "ANA, CAROLINA DA SILVA",
          date: "2023-02-25",
          amount: 1010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 20,
    name: "CASSIO",
    transactions: {
      aggregation: 7020,
      transactionAvg: 7020,
      transactions: [
        {
          sender: "CASSIO, DE OLIVEIRA SILVA",
          receiver: "CASSIO, DE OLIVEIRA SILVA",
          date: "2022-12-19",
          amount: 4010,
        },
        {
          sender: "CASSIO, DE OLIVEIRA SILVA",
          receiver: "CASSIO, DE OLIVEIRA SILVA",
          date: "2022-12-19",
          amount: 3010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 21,
    name: "CELSO",
    transactions: {
      aggregation: 3750,
      transactionAvg: 1875,
      transactions: [
        {
          sender: "CELSO, RODRIGUES DIAS",
          receiver: "ANA APARECIDA, GOMES PEREIRA",
          date: "2022-03-02",
          amount: 2010,
        },
        {
          sender: "CELSO, RODRIGUES DIAS",
          receiver: "THAIS CRISTINA, GOMES DE ALMEIDA",
          date: "2022-03-02",
          amount: 370,
        },
        {
          sender: "CELSO, RODRIGUES DIAS",
          receiver: "JULIANA, GOMES DE ALMEIDA",
          date: "2022-03-02",
          amount: 1370,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 22,
    name: "CHEILA CRISTINA",
    transactions: {
      aggregation: 3220,
      transactionAvg: 3220,
      transactions: [
        {
          sender: "CHEILA CRISTINA, GONTIJO",
          receiver: "CHEILA CRISTINA, GONTIJO",
          date: "2022-04-20",
          amount: 210,
        },
        {
          sender: "CHEILA CRISTINA, GONTIJO",
          receiver: "PATRICIA, MORAIS ARAUJO OLIVEIRA",
          date: "2022-04-20",
          amount: 3010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 23,
    name: "CLAUDIO",
    transactions: {
      aggregation: 4366.68,
      transactionAvg: 2183.34,
      transactions: [
        {
          sender: "CLAUDIO, SANTOS VITOR",
          receiver: "JOSE ITAMAR OLIVEIRA, DA CRUZ",
          date: "2023-01-30",
          amount: 2414.81,
        },
        {
          sender: "CLAUDIO, SANTOS VITOR",
          receiver: "SIMONE, MENDES RODRIGUES LIMA",
          date: "2023-01-30",
          amount: 1412.81,
        },
        {
          sender: "CLAUDIO, SANTOS VITOR",
          receiver: "JOSE ITAMAR OLIVEIRA, DA CRUZ",
          date: "2023-01-30",
          amount: 539.06,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 24,
    name: "CLEBER",
    transactions: {
      aggregation: 4520,
      transactionAvg: 4520,
      transactions: [
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "CLEBER LAUREANO, DUQUE",
          date: "2022-04-18",
          amount: 1810,
        },
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "CLEBER LAUREANO, DUQUE",
          date: "2022-04-18",
          amount: 2710,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 25,
    name: "CLEBER",
    transactions: {
      aggregation: 5231.99,
      transactionAvg: 5231.99,
      transactions: [
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "CLEBER LAUREANO, DUQUE",
          date: "2022-05-29",
          amount: 2010,
        },
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "MARIA ELIANA, BARBOSA",
          date: "2022-05-29",
          amount: 3221.99,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 26,
    name: "CLEBER",
    transactions: {
      aggregation: 3520,
      transactionAvg: 3520,
      transactions: [
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "CLEBER LAUREANO, DUQUE",
          date: "2022-12-04",
          amount: 2010,
        },
        {
          sender: "CLEBER, LAUREANO DUQUE",
          receiver: "CLEBER LAUREANO, DUQUE",
          date: "2022-12-04",
          amount: 1510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 27,
    name: "CLENIO",
    transactions: {
      aggregation: 3162.76,
      transactionAvg: 3162.76,
      transactions: [
        {
          sender: "CLENIO, ALVES DA SILVA",
          receiver: "ADILSON, MAIA",
          date: "2023-02-19",
          amount: 2962.76,
        },
        {
          sender: "CLENIO, ALVES DA SILVA",
          receiver: "JOYCE, RODRIGUES DA SILVA",
          date: "2023-02-19",
          amount: 200,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 28,
    name: "CLESIO",
    transactions: {
      aggregation: 6331.64,
      transactionAvg: 6331.64,
      transactions: [
        {
          sender: "CLESIO, ANDRADE DE OLIVEIRA",
          receiver: "MARIA DAS GRACAS, DE SIQUEIRA",
          date: "2023-02-13",
          amount: 2010,
        },
        {
          sender: "CLESIO, ANDRADE DE OLIVEIRA",
          receiver: "MARIA DAS GRACAS, DE SIQUEIRA",
          date: "2023-02-13",
          amount: 4321.64,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 29,
    name: "CLODOALDO LUIZ",
    transactions: {
      aggregation: 3410,
      transactionAvg: 3410,
      transactions: [
        {
          sender: "CLODOALDO LUIZ, LOPES DA SILVA",
          receiver: "DEBORA, PEREIRA CARNAUBA",
          date: "2022-04-13",
          amount: 2000,
        },
        {
          sender: "CLODOALDO LUIZ, LOPES DA SILVA",
          receiver: "DEBORA, PEREIRA CARNAUBA",
          date: "2022-04-13",
          amount: 1410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 30,
    name: "CLODOALDO LUIZ",
    transactions: {
      aggregation: 4020,
      transactionAvg: 4020,
      transactions: [
        {
          sender: "CLODOALDO LUIZ, LOPES DA SILVA",
          receiver: "ELIANE ALVES, LOPES",
          date: "2022-09-20",
          amount: 1510,
        },
        {
          sender: "CLODOALDO LUIZ, LOPES DA SILVA",
          receiver: "DEBORA, PEREIRA CARNAUBA",
          date: "2022-09-20",
          amount: 2510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 31,
    name: "CRISTIANE",
    transactions: {
      aggregation: 5420,
      transactionAvg: 5420,
      transactions: [
        {
          sender: "CRISTIANE, OLIVEIRA DA SILVA",
          receiver: "WALTER ANTONIO, PEREIRA",
          date: "2022-06-15",
          amount: 5010,
        },
        {
          sender: "CRISTIANE, OLIVEIRA DA SILVA",
          receiver: "IVONETE DE FATIMA, BATISTA OLIVEIRA",
          date: "2022-06-15",
          amount: 410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 32,
    name: "DAIANE",
    transactions: {
      aggregation: 12010,
      transactionAvg: 12010,
      transactions: [
        {
          sender: "DAIANE, SILVA CRUZ",
          receiver: "DAIANE, SILVA CRUZ",
          date: "2022-09-09",
          amount: 6005,
        },
        {
          sender: "DAIANE, SILVA CRUZ",
          receiver: "DAIANE, SILVA CRUZ",
          date: "2022-09-09",
          amount: 6005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 33,
    name: "DALMIRA COTA",
    transactions: {
      aggregation: 7300.469999999999,
      transactionAvg: 3650.2349999999997,
      transactions: [
        {
          sender: "DALMIRA COTA, CAETANO",
          receiver: "ANA PAULA, DE DEUS BARCELOS COUTO",
          date: "2022-10-28",
          amount: 90.04,
        },
        {
          sender: "DALMIRA COTA, CAETANO",
          receiver: "BENAYA, COTA CUNHA",
          date: "2022-10-28",
          amount: 144.36,
        },
        {
          sender: "DALMIRA COTA, CAETANO",
          receiver: "TIAGO WILSON, ALVES GODINHO",
          date: "2022-10-28",
          amount: 7066.07,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 34,
    name: "DANILO",
    transactions: {
      aggregation: 5020,
      transactionAvg: 5020,
      transactions: [
        {
          sender: "DANILO, SANTANA DA SILVA",
          receiver: "DANILO, SANTANA DA SILVA",
          date: "2022-06-02",
          amount: 3510,
        },
        {
          sender: "DANILO, SANTANA DA SILVA",
          receiver: "DANILO, SANTANA DA SILVA",
          date: "2022-06-02",
          amount: 1510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 35,
    name: "DECIO",
    transactions: {
      aggregation: 5019.68,
      transactionAvg: 1003.936,
      transactions: [
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "DOUGLAS, RIBEIRO FERNANDES",
          date: "2022-03-17",
          amount: 1018.06,
        },
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "JONATHAN, RODRIGUES ARAUJO",
          date: "2022-03-17",
          amount: 715.65,
        },
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "MATHEUS, LUIZ DE SOUZA ROCHA",
          date: "2022-03-17",
          amount: 614.84,
        },
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "EDGAR JOAO, DA SILVA",
          date: "2022-03-17",
          amount: 393.06,
        },
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "ROGERIO, BATISTA DE MORAIS",
          date: "2022-03-17",
          amount: 1219.68,
        },
        {
          sender: "DECIO, BORGES ROCHA",
          receiver: "DECIO, BORGES ROCHA",
          date: "2022-03-17",
          amount: 1058.39,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 36,
    name: "DIEGO HENRIQUE",
    transactions: {
      aggregation: 4431.76,
      transactionAvg: 4431.76,
      transactions: [
        {
          sender: "DIEGO HENRIQUE, FERREIRA",
          receiver: "IAGO DEIVID, FERREIRA",
          date: "2022-06-08",
          amount: 4211.68,
        },
        {
          sender: "DIEGO HENRIQUE, FERREIRA",
          receiver: "DIEGO HENRIQUE, FERREIRA",
          date: "2022-06-08",
          amount: 220.08,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 37,
    name: "DIRCE",
    transactions: {
      aggregation: 3839.83,
      transactionAvg: 1919.915,
      transactions: [
        {
          sender: "DIRCE, PEREIRA DOS SANTOS",
          receiver: "WELLINGTON SUENES, RODRIGUES DA SILVA",
          date: "2022-10-01",
          amount: 1639.83,
        },
        {
          sender: "DIRCE, PEREIRA DOS SANTOS",
          receiver: "DIRCE, PEREIRA DOS SANTOS",
          date: "2022-10-01",
          amount: 2000,
        },
        {
          sender: "DIRCE, PEREIRA DOS SANTOS",
          receiver: "VICENTE PEREIRA, DOS SANTOS",
          date: "2022-10-01",
          amount: 200,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 38,
    name: "DOUGLAS",
    transactions: {
      aggregation: 3411.58,
      transactionAvg: 3411.58,
      transactions: [
        {
          sender: "DOUGLAS, FERREIRA ALVES",
          receiver: "DEVAIR, ALVES DA SILVA",
          date: "2022-08-01",
          amount: 2256.97,
        },
        {
          sender: "DOUGLAS, FERREIRA ALVES",
          receiver: "VILMAR, RIBEIRO DA FONSECA",
          date: "2022-08-01",
          amount: 1154.61,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 39,
    name: "EDER GERALDO",
    transactions: {
      aggregation: 3920,
      transactionAvg: 3920,
      transactions: [
        {
          sender: "EDER GERALDO, BRANQUINHO",
          receiver: "EDER GERALDO, BRAQUINHO",
          date: "2022-03-15",
          amount: 1510,
        },
        {
          sender: "EDER GERALDO, BRANQUINHO",
          receiver: "EDER GERALDO, BRAQUINHO",
          date: "2022-03-15",
          amount: 2410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 40,
    name: "EDILAILA GERALDA",
    transactions: {
      aggregation: 4021.51,
      transactionAvg: 2010.755,
      transactions: [
        {
          sender: "EDILAILA GERALDA, DE SOUSA",
          receiver: "MARIA DALVA, SOARES DE SOUSA",
          date: "2022-03-28",
          amount: 3407.03,
        },
        {
          sender: "EDILAILA GERALDA, DE SOUSA",
          receiver: "EMISLEI SOARES, DE SOUSA",
          date: "2022-03-28",
          amount: 392.17,
        },
        {
          sender: "EDILAILA GERALDA, DE SOUSA",
          receiver: "ALICE SOUSA, MORAES",
          date: "2022-03-28",
          amount: 222.31,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 41,
    name: "EDNA APARECIDA",
    transactions: {
      aggregation: 6190,
      transactionAvg: 3095,
      transactions: [
        {
          sender: "EDNA APARECIDA, FERREIRA",
          receiver: "EDNA APARECIDA, FERREIRA",
          date: "2022-04-12",
          amount: 2205,
        },
        {
          sender: "EDNA APARECIDA, FERREIRA",
          receiver: "IGREJA BATISTA, MANANCIAL DE ARAXA",
          date: "2022-04-12",
          amount: 180,
        },
        {
          sender: "EDNA APARECIDA, FERREIRA",
          receiver: "EDNA APARECIDA, FERREIRA",
          date: "2022-04-12",
          amount: 3805,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 42,
    name: "EDSON AMORIM",
    transactions: {
      aggregation: 3683.46,
      transactionAvg: 3683.46,
      transactions: [
        {
          sender: "EDSON AMORIM, DE MORAIS PESSOA",
          receiver: "SEBASTIAO, ROBERTO FLAVIO",
          date: "2022-03-09",
          amount: 617.24,
        },
        {
          sender: "EDSON AMORIM, DE MORAIS PESSOA",
          receiver: "JULIANO DA SILVA, LONDE",
          date: "2022-03-09",
          amount: 3066.22,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 43,
    name: "EDSON AMORIM",
    transactions: {
      aggregation: 3305.06,
      transactionAvg: 1652.53,
      transactions: [
        {
          sender: "EDSON AMORIM, DE MORAIS PESSOA",
          receiver: "VALTRA ADMINISTRADORA, DE CONSORCIOS LTDA",
          date: "2022-08-05",
          amount: 745.74,
        },
        {
          sender: "EDSON AMORIM, DE MORAIS PESSOA",
          receiver: "EDSON AMORIM, DE MORAIS PESSOA",
          date: "2022-08-05",
          amount: 1954.32,
        },
        {
          sender: "EDSON AMORIM, DE MORAIS PESSOA",
          receiver: "JULIANO DA SILVA, LONDE",
          date: "2022-08-05",
          amount: 605,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 44,
    name: "EDSON",
    transactions: {
      aggregation: 4410,
      transactionAvg: 4410,
      transactions: [
        {
          sender: "EDSON, NUNES BARRA",
          receiver: "REIDNER GABRIEL, NUNES BARRA",
          date: "2022-12-06",
          amount: 500,
        },
        {
          sender: "EDSON, NUNES BARRA",
          receiver: "EDSON NUNES, BARRA",
          date: "2022-12-06",
          amount: 3910,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 45,
    name: "EDSON",
    transactions: {
      aggregation: 7260,
      transactionAvg: 7260,
      transactions: [
        {
          sender: "EDSON, NUNES BARRA",
          receiver: "REIDNER GABRIEL, NUNES BARRA",
          date: "2023-01-09",
          amount: 450,
        },
        {
          sender: "EDSON, NUNES BARRA",
          receiver: "EDSON NUNES, BARRA",
          date: "2023-01-09",
          amount: 6810,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 46,
    name: "ELISABETH",
    transactions: {
      aggregation: 5589.09,
      transactionAvg: 5589.09,
      transactions: [
        {
          sender: "ELISABETH, ABGAIL DE BRITO",
          receiver: "ANA FLAVIA, SILVA",
          date: "2022-11-13",
          amount: 1760,
        },
        {
          sender: "ELISABETH, ABGAIL DE BRITO",
          receiver: "ROGERIO, JOSE DE BRITO",
          date: "2022-11-13",
          amount: 3829.09,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 47,
    name: "ELISABETH",
    transactions: {
      aggregation: 4220,
      transactionAvg: 4220,
      transactions: [
        {
          sender: "ELISABETH, ABGAIL DE BRITO",
          receiver: "ANA FLAVIA C, SILVA",
          date: "2023-01-08",
          amount: 4010,
        },
        {
          sender: "ELISABETH, ABGAIL DE BRITO",
          receiver: "EULER A, GONCALVES",
          date: "2023-01-08",
          amount: 210,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 48,
    name: "ELTON",
    transactions: {
      aggregation: 3661,
      transactionAvg: 1830.5,
      transactions: [
        {
          sender: "ELTON, ALVES DOS SANTOS",
          receiver: "VANILDO, DE ALMEIDA",
          date: "2022-05-03",
          amount: 1070.57,
        },
        {
          sender: "ELTON, ALVES DOS SANTOS",
          receiver: "CLARET, BUENO PEDROSO",
          date: "2022-05-03",
          amount: 578.77,
        },
        {
          sender: "ELTON, ALVES DOS SANTOS",
          receiver: "ELTON, ALVES DOS SANTOS",
          date: "2022-05-03",
          amount: 2011.66,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 49,
    name: "FERNANDO AUGUSTO",
    transactions: {
      aggregation: 3010,
      transactionAvg: 3010,
      transactions: [
        {
          sender: "FERNANDO AUGUSTO, MENDES",
          receiver: "FERNANDO AUGUSTO, MENDES",
          date: "2022-04-12",
          amount: 2005,
        },
        {
          sender: "FERNANDO AUGUSTO, MENDES",
          receiver: "ANTONIO JOUBERT, MENDES",
          date: "2022-04-12",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 50,
    name: "GEOVANE JOSE",
    transactions: {
      aggregation: 7020,
      transactionAvg: 7020,
      transactions: [
        {
          sender: "GEOVANE JOSE, DA SILVA",
          receiver: "GEOVANE JOSE, DA SILVA",
          date: "2022-10-26",
          amount: 3010,
        },
        {
          sender: "GEOVANE JOSE, DA SILVA",
          receiver: "GEOVANE JOSE, DA SILVA",
          date: "2022-10-26",
          amount: 4010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 51,
    name: "GEOVANE",
    transactions: {
      aggregation: 5470.73,
      transactionAvg: 5470.73,
      transactions: [
        {
          sender: "GEOVANE, HENRIQUE DA SILVA",
          receiver: "DERMEVAL, RIBET",
          date: "2022-06-08",
          amount: 2316.08,
        },
        {
          sender: "GEOVANE, HENRIQUE DA SILVA",
          receiver: "ELANDIA, TEREZINHA ALVES FERREIRA",
          date: "2022-06-08",
          amount: 3154.65,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 52,
    name: "GEOVANE",
    transactions: {
      aggregation: 4620,
      transactionAvg: 4620,
      transactions: [
        {
          sender: "GEOVANE, HENRIQUE DA SILVA",
          receiver: "GEOVANE, HENRIQUE DA SILVA",
          date: "2022-08-09",
          amount: 1210,
        },
        {
          sender: "GEOVANE, HENRIQUE DA SILVA",
          receiver: "GEOVANE, HENRIQUE DA SILVA",
          date: "2022-08-09",
          amount: 3410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 53,
    name: "GERALDO",
    transactions: {
      aggregation: 3164.02,
      transactionAvg: 3164.02,
      transactions: [
        {
          sender: "GERALDO, DAMAZIO DOS SANTOS",
          receiver: "SIMONE FRANCISCA, OLIVEIRA",
          date: "2022-03-13",
          amount: 3052.6,
        },
        {
          sender: "GERALDO, DAMAZIO DOS SANTOS",
          receiver: "AUREA GOMES, DE JESUS MOTA",
          date: "2022-03-13",
          amount: 111.42,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 54,
    name: "GILBERTO JOSE",
    transactions: {
      aggregation: 7276.46,
      transactionAvg: 7276.46,
      transactions: [
        {
          sender: "GILBERTO JOSE, DE LIMA",
          receiver: "RAMON, ALMEIDA FARIA",
          date: "2022-11-07",
          amount: 3648.17,
        },
        {
          sender: "GILBERTO JOSE, DE LIMA",
          receiver: "RAMON, ALMEIDA FARIA",
          date: "2022-11-07",
          amount: 3628.29,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 55,
    name: "GILDA APARECIDA",
    transactions: {
      aggregation: 4015,
      transactionAvg: 4015,
      transactions: [
        {
          sender: "GILDA APARECIDA, ALVES ALBUQUERQUE",
          receiver: "LEANDRA ALVES, ALBUQUERQUE",
          date: "2022-09-26",
          amount: 310,
        },
        {
          sender: "GILDA APARECIDA, ALVES ALBUQUERQUE",
          receiver: "GILDA APARECIDA, ALVES ALBUQUERQUE",
          date: "2022-09-26",
          amount: 3705,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 56,
    name: "GISLEI",
    transactions: {
      aggregation: 5150,
      transactionAvg: 5150,
      transactions: [
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "INGRIT, L B SILVA",
          date: "2022-04-11",
          amount: 5000,
        },
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "MOISES, BARCELOS DA SILVA",
          date: "2022-04-11",
          amount: 150,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 57,
    name: "GISLEI",
    transactions: {
      aggregation: 6371.42,
      transactionAvg: 2123.806666666667,
      transactions: [
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "LAZARO BATISTA, DE OLIVEIRA",
          date: "2022-05-15",
          amount: 1115,
        },
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "VANILDO CERQUEIRA, GOMES",
          date: "2022-05-15",
          amount: 601.42,
        },
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "MOISES, BARCELOS DA SILVA",
          date: "2022-05-15",
          amount: 150,
        },
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "INGRIT, L B SILVA",
          date: "2022-05-15",
          amount: 4505,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 58,
    name: "GISLEI",
    transactions: {
      aggregation: 7100,
      transactionAvg: 7100,
      transactions: [
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "EDUARDO, SILVA BICALHO",
          date: "2022-07-25",
          amount: 4314.43,
        },
        {
          sender: "GISLEI, BARCELOS DA SILVA BARBOSA",
          receiver: "INGRIT, L B SILVA",
          date: "2022-07-25",
          amount: 2785.57,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 59,
    name: "GUILHERME SANTIAGO",
    transactions: {
      aggregation: 4520,
      transactionAvg: 4520,
      transactions: [
        {
          sender: "GUILHERME SANTIAGO, SANTOS",
          receiver: "GUILHERME, SANTIAGO SANTOS",
          date: "2022-09-26",
          amount: 4010,
        },
        {
          sender: "GUILHERME SANTIAGO, SANTOS",
          receiver: "GUILHERME, SANTIAGO SANTOS",
          date: "2022-09-26",
          amount: 510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 60,
    name: "GUIRLEI",
    transactions: {
      aggregation: 5499.45,
      transactionAvg: 5499.45,
      transactions: [
        {
          sender: "GUIRLEI, HONORATO PATRICIO",
          receiver: "ISAC C, SILVA",
          date: "2022-09-21",
          amount: 3510,
        },
        {
          sender: "GUIRLEI, HONORATO PATRICIO",
          receiver: "ISAC C, SILVA",
          date: "2022-09-21",
          amount: 1989.45,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 61,
    name: "GUSTAVO",
    transactions: {
      aggregation: 3992.75,
      transactionAvg: 3992.75,
      transactions: [
        {
          sender: "GUSTAVO, SIQUEIRA RODRIGUES",
          receiver: "BELCHIOR, ANTONIO DE AMORIM",
          date: "2022-04-04",
          amount: 2207.8,
        },
        {
          sender: "GUSTAVO, SIQUEIRA RODRIGUES",
          receiver: "CASA DO, FAZENDEIRO",
          date: "2022-04-04",
          amount: 1784.95,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 62,
    name: "GUSTAVO",
    transactions: {
      aggregation: 3015.77,
      transactionAvg: 1507.885,
      transactions: [
        {
          sender: "GUSTAVO, SIQUEIRA RODRIGUES",
          receiver: "ALESSANDRO, PORTILHO DA SILVA",
          date: "2022-05-17",
          amount: 411.61,
        },
        {
          sender: "GUSTAVO, SIQUEIRA RODRIGUES",
          receiver: "LEONARDO, LUCAS GONCALVES FERREIRA",
          date: "2022-05-17",
          amount: 2018.03,
        },
        {
          sender: "GUSTAVO, SIQUEIRA RODRIGUES",
          receiver: "PRIMOS PRESTACAO, DE SERVICOS LTDA",
          date: "2022-05-17",
          amount: 586.13,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 63,
    name: "HIGOR",
    transactions: {
      aggregation: 4260,
      transactionAvg: 4260,
      transactions: [
        {
          sender: "HIGOR, CARDOSO GONCALVES",
          receiver: "YANA, CAMILA GOMES VIEIRA",
          date: "2022-12-19",
          amount: 1060,
        },
        {
          sender: "HIGOR, CARDOSO GONCALVES",
          receiver: "HIGOR, CARDOSO GONCALVES",
          date: "2022-12-19",
          amount: 3200,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 64,
    name: "ISAAC MICHEL",
    transactions: {
      aggregation: 5520,
      transactionAvg: 5520,
      transactions: [
        {
          sender: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          receiver: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          date: "2022-04-24",
          amount: 1510,
        },
        {
          sender: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          receiver: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          date: "2022-04-24",
          amount: 4010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 65,
    name: "ISAAC MICHEL",
    transactions: {
      aggregation: 6910,
      transactionAvg: 6910,
      transactions: [
        {
          sender: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          receiver: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          date: "2022-07-15",
          amount: 4000,
        },
        {
          sender: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          receiver: "ISAAC MICHEL, DE OLIVEIRA VALENTE",
          date: "2022-07-15",
          amount: 2910,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 66,
    name: "IVANI JOSE",
    transactions: {
      aggregation: 3152.29,
      transactionAvg: 1576.145,
      transactions: [
        {
          sender: "IVANI JOSE, RODRIGUES",
          receiver: "IVANI JOSE, RODRIGUES",
          date: "2022-08-07",
          amount: 401.39,
        },
        {
          sender: "IVANI JOSE, RODRIGUES",
          receiver: "IVACI, JOSE RODRIGUES",
          date: "2022-08-07",
          amount: 792.78,
        },
        {
          sender: "IVANI JOSE, RODRIGUES",
          receiver: "EDPO, MENDES OLIVEIRA",
          date: "2022-08-07",
          amount: 1958.12,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 67,
    name: "JACQUELINE",
    transactions: {
      aggregation: 4120,
      transactionAvg: 4120,
      transactions: [
        {
          sender: "JACQUELINE, SILVEIRA",
          receiver: "JUCILENE, LACERDA SILVEIRA",
          date: "2022-09-28",
          amount: 2110,
        },
        {
          sender: "JACQUELINE, SILVEIRA",
          receiver: "JUCILENE, LACERDA SILVEIRA",
          date: "2022-09-28",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 68,
    name: "JANIO",
    transactions: {
      aggregation: 6019.99,
      transactionAvg: 6019.99,
      transactions: [
        {
          sender: "JANIO, FERREIRA BASTOS",
          receiver: "JANIO FERREIRA, BASTOS",
          date: "2023-02-16",
          amount: 4056.87,
        },
        {
          sender: "JANIO, FERREIRA BASTOS",
          receiver: "JANIO FERREIRA, BASTOS",
          date: "2023-02-16",
          amount: 1963.12,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 69,
    name: "JANIO",
    transactions: {
      aggregation: 6020,
      transactionAvg: 6020,
      transactions: [
        {
          sender: "JANIO, FERREIRA BASTOS",
          receiver: "JANIO FERREIRA, BASTOS",
          date: "2022-10-25",
          amount: 4010,
        },
        {
          sender: "JANIO, FERREIRA BASTOS",
          receiver: "JANIO FERREIRA, BASTOS",
          date: "2022-10-25",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 70,
    name: "JERONIMO",
    transactions: {
      aggregation: 3020,
      transactionAvg: 3020,
      transactions: [
        {
          sender: "JERONIMO, FERNANDES DA SILVA",
          receiver: "VISLENE DE FATIMA, AZEVEDO SILVA",
          date: "2022-05-17",
          amount: 2910,
        },
        {
          sender: "JERONIMO, FERNANDES DA SILVA",
          receiver: "FLAVIO, JOSE SOARES",
          date: "2022-05-17",
          amount: 110,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 71,
    name: "JEUBES JEYME",
    transactions: {
      aggregation: 6400,
      transactionAvg: 6400,
      transactions: [
        {
          sender: "JEUBES JEYME, PINHEIRO RODRIGUES GOMES",
          receiver: "JEUBES JEYME, PINHEIRO RODRIGUES GOMES",
          date: "2022-06-21",
          amount: 5900,
        },
        {
          sender: "JEUBES JEYME, PINHEIRO RODRIGUES GOMES",
          receiver: "JEUBES JEYME, PINHEIRO RODRIGUES GOMES",
          date: "2022-06-21",
          amount: 500,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 72,
    name: "JOAO A",
    transactions: {
      aggregation: 3020,
      transactionAvg: 3020,
      transactions: [
        {
          sender: "JOAO A, PESSOA-JUNIOR",
          receiver: "JOAO ALVES, PESSOA JUNIOR",
          date: "2022-04-08",
          amount: 2010,
        },
        {
          sender: "JOAO A, PESSOA-JUNIOR",
          receiver: "JOAO ALVES, PESSOA JUNIOR",
          date: "2022-04-08",
          amount: 1010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 73,
    name: "JOAO EDSON",
    transactions: {
      aggregation: 3415,
      transactionAvg: 3415,
      transactions: [
        {
          sender: "JOAO EDSON, BOAVENTURA ROCHA",
          receiver: "ELIANA, MUNDIM BRAGA",
          date: "2022-09-06",
          amount: 2305,
        },
        {
          sender: "JOAO EDSON, BOAVENTURA ROCHA",
          receiver: "WILSON, DE OLIVEIRA PIMENTA",
          date: "2022-09-06",
          amount: 1110,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 74,
    name: "JOAO EDSON",
    transactions: {
      aggregation: 3010,
      transactionAvg: 3010,
      transactions: [
        {
          sender: "JOAO EDSON, BOAVENTURA ROCHA",
          receiver: "ELIANA, MUNDIM BRAGA",
          date: "2022-10-18",
          amount: 2005,
        },
        {
          sender: "JOAO EDSON, BOAVENTURA ROCHA",
          receiver: "MARIANA, MUNDIM BOAVENTURA",
          date: "2022-10-18",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 75,
    name: "JOAO FRANCISCO",
    transactions: {
      aggregation: 3860,
      transactionAvg: 3860,
      transactions: [
        {
          sender: "JOAO FRANCISCO, DE ARAUJO",
          receiver: "TERPLANI TERRENOS E PLANEJAMENTOS, IMOBILIARIOS LTDA",
          date: "2023-02-22",
          amount: 1931.26,
        },
        {
          sender: "JOAO FRANCISCO, DE ARAUJO",
          receiver: "TERPLANI TERRENOS E PLANEJAMENTOS, IMOBILIARIOS LTDA",
          date: "2023-02-22",
          amount: 1928.74,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 76,
    name: "JOHN LENNON",
    transactions: {
      aggregation: 7075.13,
      transactionAvg: 7075.13,
      transactions: [
        {
          sender: "JOHN LENNON, DE SOUSA SOARES",
          receiver: "JULIANA APARECIDA, DE SOUSA SOARES",
          date: "2022-09-26",
          amount: 4000,
        },
        {
          sender: "JOHN LENNON, DE SOUSA SOARES",
          receiver: "JULIANA APARECIDA, DE SOUSA SOARES",
          date: "2022-09-26",
          amount: 3075.13,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 77,
    name: "JOICE DANIELA",
    transactions: {
      aggregation: 3331.61,
      transactionAvg: 3331.61,
      transactions: [
        {
          sender: "JOICE DANIELA, DA SILVA",
          receiver: "JOICE DANIELA, DA SILVA",
          date: "2022-03-17",
          amount: 211.61,
        },
        {
          sender: "JOICE DANIELA, DA SILVA",
          receiver: "JUSSARA, FERREIRA DIAS",
          date: "2022-03-17",
          amount: 3120,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 78,
    name: "JOSE RAFAEL",
    transactions: {
      aggregation: 3911.0599999999995,
      transactionAvg: 3911.0599999999995,
      transactions: [
        {
          sender: "JOSE RAFAEL, DE ARAUJO",
          receiver: "CRISTON G, LIMA",
          date: "2022-10-20",
          amount: 2733.74,
        },
        {
          sender: "JOSE RAFAEL, DE ARAUJO",
          receiver: "ISOMAR PEREIRA, PINTO",
          date: "2022-10-20",
          amount: 1177.32,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 79,
    name: "JOSELMA",
    transactions: {
      aggregation: 5774.72,
      transactionAvg: 5774.72,
      transactions: [
        {
          sender: "JOSELMA, FREITAS MENEGUEL",
          receiver: "JULIO ALEX, SANDRO MENEGUEL",
          date: "2022-07-15",
          amount: 5010,
        },
        {
          sender: "JOSELMA, FREITAS MENEGUEL",
          receiver: "JAQUELINE, FREITAS MENDONCA",
          date: "2022-07-15",
          amount: 764.72,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 80,
    name: "JOSIANE",
    transactions: {
      aggregation: 3104.6,
      transactionAvg: 3104.6,
      transactions: [
        {
          sender: "JOSIANE, COSTA VIEIRA",
          receiver: "DOUGLAS, WILLIAM DA SILVA",
          date: "2022-04-11",
          amount: 1094.6,
        },
        {
          sender: "JOSIANE, COSTA VIEIRA",
          receiver: "JOSIANE, COSTA VIEIRA",
          date: "2022-04-11",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 81,
    name: "KARINA MIRELE",
    transactions: {
      aggregation: 6900,
      transactionAvg: 6900,
      transactions: [
        {
          sender: "KARINA MIRELE, DE OLIVEIRA",
          receiver: "CLEUZA MARIA, GONCALVES OLIVEIRA",
          date: "2022-04-06",
          amount: 6300,
        },
        {
          sender: "KARINA MIRELE, DE OLIVEIRA",
          receiver: "CLEUZA MARIA, GONCALVES OLIVEIRA",
          date: "2022-04-06",
          amount: 600,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 82,
    name: "LASARO CESAR",
    transactions: {
      aggregation: 3015,
      transactionAvg: 3015,
      transactions: [
        {
          sender: "LASARO CESAR, QUINTINO",
          receiver: "FLORACI, DA CONCEICAO CARDOSO",
          date: "2022-06-14",
          amount: 1010,
        },
        {
          sender: "LASARO CESAR, QUINTINO",
          receiver: "LASARO, CESAR QUINTINO",
          date: "2022-06-14",
          amount: 2005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 83,
    name: "LEONARDO BRAGA",
    transactions: {
      aggregation: 5110,
      transactionAvg: 5110,
      transactions: [
        {
          sender: "LEONARDO BRAGA, VIEIRA",
          receiver: "LEONARDO BRAGA, VIEIRA",
          date: "2022-10-28",
          amount: 3005,
        },
        {
          sender: "LEONARDO BRAGA, VIEIRA",
          receiver: "SAMARA, BARBOSA FIGUEIREDO",
          date: "2022-10-28",
          amount: 2105,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 84,
    name: "LUANA",
    transactions: {
      aggregation: 3088.44,
      transactionAvg: 3088.44,
      transactions: [
        {
          sender: "LUANA, FERREIRA DA SILVA",
          receiver: "ROSANE GUISSONI, DUTRA BARRA",
          date: "2022-06-13",
          amount: 783.44,
        },
        {
          sender: "LUANA, FERREIRA DA SILVA",
          receiver: "LUANA, FERREIRA DA SILVA",
          date: "2022-06-13",
          amount: 2305,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 85,
    name: "LUCIANO LUCAS",
    transactions: {
      aggregation: 5110,
      transactionAvg: 5110,
      transactions: [
        {
          sender: "LUCIANO LUCAS, RODRIGUES DOS SANTOS",
          receiver: "LUCIANO LUCAS, RODRIGUES DOS SANTOS",
          date: "2022-10-10",
          amount: 4010,
        },
        {
          sender: "LUCIANO LUCAS, RODRIGUES DOS SANTOS",
          receiver: "LUCIANO LUCAS, RODRIGUES DOS SANTOS",
          date: "2022-10-10",
          amount: 1100,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 86,
    name: "LUIZA",
    transactions: {
      aggregation: 7020,
      transactionAvg: 7020,
      transactions: [
        {
          sender: "LUIZA, ROCHA DE ANDRADE GONTIJO",
          receiver: "LUIZA ROCHA DE ANDRADE, GONTIJO",
          date: "2022-11-03",
          amount: 5510,
        },
        {
          sender: "LUIZA, ROCHA DE ANDRADE GONTIJO",
          receiver: "LUIZA ROCHA DE ANDRADE, GONTIJO",
          date: "2022-11-03",
          amount: 1510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 87,
    name: "LUIZA",
    transactions: {
      aggregation: 3960,
      transactionAvg: 3960,
      transactions: [
        {
          sender: "LUIZA, ROCHA DE ANDRADE GONTIJO",
          receiver: "LUIZA ROCHA DE ANDRADE, GONTIJO",
          date: "2022-12-13",
          amount: 2450,
        },
        {
          sender: "LUIZA, ROCHA DE ANDRADE GONTIJO",
          receiver: "LUIZA ROCHA DE ANDRADE, GONTIJO",
          date: "2022-12-13",
          amount: 1510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 88,
    name: "MARIA G",
    transactions: {
      aggregation: 4320,
      transactionAvg: 4320,
      transactions: [
        {
          sender: "MARIA G, SOARES DA SILVA",
          receiver: "ANA ELISA, V F SOARES",
          date: "2022-10-12",
          amount: 310,
        },
        {
          sender: "MARIA G, SOARES DA SILVA",
          receiver: "MARIA GENY, SOARES DA SILVA",
          date: "2022-10-12",
          amount: 4010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 89,
    name: "MARILHA",
    transactions: {
      aggregation: 3182.75,
      transactionAvg: 1591.375,
      transactions: [
        {
          sender: "MARILHA, BATISTA FRANCA DE SOUSA",
          receiver: "CLEUDENY VIEIRA, LEITE FURTADO",
          date: "2022-10-03",
          amount: 994.25,
        },
        {
          sender: "MARILHA, BATISTA FRANCA DE SOUSA",
          receiver: "RAIMUNDO, SOUZA CUNHA",
          date: "2022-10-03",
          amount: 1978.5,
        },
        {
          sender: "MARILHA, BATISTA FRANCA DE SOUSA",
          receiver: "AMINTOR, FERREIRA SOUZA",
          date: "2022-10-03",
          amount: 210,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 90,
    name: "MATHEUS FELIPE",
    transactions: {
      aggregation: 7020,
      transactionAvg: 7020,
      transactions: [
        {
          sender: "MATHEUS FELIPE, DE MELO SIRONI",
          receiver: "MATHEUS FELIPE, DE MELO SIRONI",
          date: "2022-10-08",
          amount: 3010,
        },
        {
          sender: "MATHEUS FELIPE, DE MELO SIRONI",
          receiver: "MATHEUS FELIPE, DE MELO SIRONI",
          date: "2022-10-08",
          amount: 4010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 91,
    name: "MAURICIO ANTONIO",
    transactions: {
      aggregation: 11210,
      transactionAvg: 11210,
      transactions: [
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "LUCIANA ROSA, LUCAS DA SILVA",
          date: "2022-05-02",
          amount: 7200,
        },
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "LUCIANA ROSA, LUCAS DA SILVA",
          date: "2022-05-02",
          amount: 4010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 92,
    name: "MAURICIO ANTONIO",
    transactions: {
      aggregation: 3810,
      transactionAvg: 3810,
      transactions: [
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "LUCIANA ROSA, LUCAS DA SILVA",
          date: "2022-12-20",
          amount: 2800,
        },
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "LUCIANA ROSA, LUCAS DA SILVA",
          date: "2022-12-20",
          amount: 1010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 93,
    name: "MAURICIO ANTONIO",
    transactions: {
      aggregation: 4270,
      transactionAvg: 4270,
      transactions: [
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "MAURICIO ANTONIO, LUCAS DA SILVA",
          date: "2023-01-05",
          amount: 3010,
        },
        {
          sender: "MAURICIO ANTONIO, LUCAS DA SILVA",
          receiver: "MAURICIO ANTONIO, LUCAS DA SILVA",
          date: "2023-01-05",
          amount: 1260,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 94,
    name: "MAURICIO DOUGLAS",
    transactions: {
      aggregation: 7485,
      transactionAvg: 7485,
      transactions: [
        {
          sender: "MAURICIO DOUGLAS, DE ALMEIDA",
          receiver: "LUCIANA GILDA, CAMPOS COSTA",
          date: "2022-10-17",
          amount: 980,
        },
        {
          sender: "MAURICIO DOUGLAS, DE ALMEIDA",
          receiver: "ADRIANE BATISTA, DE OLIVEIRA",
          date: "2022-10-17",
          amount: 6505,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 95,
    name: "MAURICIO",
    transactions: {
      aggregation: 4421.2,
      transactionAvg: 2210.6,
      transactions: [
        {
          sender: "MAURICIO, DE SOUZA",
          receiver: "CASSIA MARIA, JACO DE SOUZA",
          date: "2022-03-08",
          amount: 110.6,
        },
        {
          sender: "MAURICIO, DE SOUZA",
          receiver: "SINARA APARECIDA, DOS REIS",
          date: "2022-03-08",
          amount: 110.6,
        },
        {
          sender: "MAURICIO, DE SOUZA",
          receiver: "MARCIO, DE SOUZA",
          date: "2022-03-08",
          amount: 4200,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 96,
    name: "MAXSUEL JUNIO",
    transactions: {
      aggregation: 3272.04,
      transactionAvg: 3272.04,
      transactions: [
        {
          sender: "MAXSUEL JUNIO, PEREIRA",
          receiver: "MAXSUEL JUNIO, PEREIRA",
          date: "2022-06-13",
          amount: 1636.02,
        },
        {
          sender: "MAXSUEL JUNIO, PEREIRA",
          receiver: "MAXSUEL JUNIO, PEREIRA",
          date: "2022-06-13",
          amount: 1636.02,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 97,
    name: "MAXSUEL JUNIO",
    transactions: {
      aggregation: 8020,
      transactionAvg: 8020,
      transactions: [
        {
          sender: "MAXSUEL JUNIO, PEREIRA",
          receiver: "MAXSUEL JUNIO, PEREIRA",
          date: "2022-10-14",
          amount: 3010,
        },
        {
          sender: "MAXSUEL JUNIO, PEREIRA",
          receiver: "MAXSUEL JUNIO, PEREIRA",
          date: "2022-10-14",
          amount: 5010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 98,
    name: "MIRACY",
    transactions: {
      aggregation: 3173.42,
      transactionAvg: 3173.42,
      transactions: [
        {
          sender: "MIRACY, ABE",
          receiver: "MAGNO, MIGUEL RONQUINI",
          date: "2022-09-14",
          amount: 3005,
        },
        {
          sender: "MIRACY, ABE",
          receiver: "NATALIA LOPES, F FERREIRA",
          date: "2022-09-14",
          amount: 168.42,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 99,
    name: "MULLER",
    transactions: {
      aggregation: 3700,
      transactionAvg: 3700,
      transactions: [
        {
          sender: "MULLER, DONIZETTE DE OLIVEIRA",
          receiver: "MULLER DONIZETE, DE OLIVEIRA",
          date: "2022-03-28",
          amount: 2500,
        },
        {
          sender: "MULLER, DONIZETTE DE OLIVEIRA",
          receiver: "WANDER JUNIO, LACERDA DE ALMEIDA",
          date: "2022-03-28",
          amount: 1200,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 100,
    name: "MULLER",
    transactions: {
      aggregation: 3546.9700000000003,
      transactionAvg: 3546.9700000000003,
      transactions: [
        {
          sender: "MULLER, DONIZETTE DE OLIVEIRA",
          receiver: "WANDER JUNIO, LACERDA DE ALMEIDA",
          date: "2022-05-19",
          amount: 2499.63,
        },
        {
          sender: "MULLER, DONIZETTE DE OLIVEIRA",
          receiver: "VIVAINE, BERNARDES DE OLIVEIRA",
          date: "2022-05-19",
          amount: 1047.34,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 101,
    name: "MURYLLO N",
    transactions: {
      aggregation: 5311.05,
      transactionAvg: 2655.525,
      transactions: [
        {
          sender: "MURYLLO N, MENDES",
          receiver: "ALVINO, TEIXEIRA MENDES",
          date: "2022-06-25",
          amount: 3896.05,
        },
        {
          sender: "MURYLLO N, MENDES",
          receiver: "EDSON, DE SOUZA CARVALHO",
          date: "2022-06-25",
          amount: 1005,
        },
        {
          sender: "MURYLLO N, MENDES",
          receiver: "SIRLEI AUGUSTA, DE LIMA",
          date: "2022-06-25",
          amount: 410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 102,
    name: "NATHALIA",
    transactions: {
      aggregation: 3165,
      transactionAvg: 3165,
      transactions: [
        {
          sender: "NATHALIA, FERNANDES MENDES",
          receiver: "ERMILDA, CORREA TAVARES ELEUTERIO",
          date: "2022-07-09",
          amount: 2946.5,
        },
        {
          sender: "NATHALIA, FERNANDES MENDES",
          receiver: "IGREJA, BATISTA BETEL DE PATOS DE MINAS",
          date: "2022-07-09",
          amount: 218.5,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 103,
    name: "NATHALIA",
    transactions: {
      aggregation: 3960,
      transactionAvg: 3960,
      transactions: [
        {
          sender: "NATHALIA, FERNANDES MENDES",
          receiver: "IGREJA, BATISTA BETEL DE PATOS DE MINAS",
          date: "2022-09-26",
          amount: 455,
        },
        {
          sender: "NATHALIA, FERNANDES MENDES",
          receiver: "ERMILDA, CORREA TAVARES ELEUTERIO",
          date: "2022-09-26",
          amount: 3505,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 104,
    name: "NATHALIA",
    transactions: {
      aggregation: 4120,
      transactionAvg: 4120,
      transactions: [
        {
          sender: "NATHALIA, FERNANDES-MENDES",
          receiver: "IGREJA, BATISTA BETEL DE PATOS DE MINAS",
          date: "2022-06-22",
          amount: 420,
        },
        {
          sender: "NATHALIA, FERNANDES-MENDES",
          receiver: "ERMILDA, CORREA TAVARES ELEUTERIO",
          date: "2022-06-22",
          amount: 3700,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 105,
    name: "NEILTON",
    transactions: {
      aggregation: 7320,
      transactionAvg: 7320,
      transactions: [
        {
          sender: "NEILTON, OLIVEIRA DA SILVA",
          receiver: "ANATOLIA LIRIO, DE LAIA",
          date: "2022-12-16",
          amount: 210,
        },
        {
          sender: "NEILTON, OLIVEIRA DA SILVA",
          receiver: "PEDRO, MARTINS",
          date: "2022-12-16",
          amount: 7110,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 106,
    name: "NEIVALDO",
    transactions: {
      aggregation: 3789.96,
      transactionAvg: 3789.96,
      transactions: [
        {
          sender: "NEIVALDO, MOREIRA PEREIRA",
          receiver: "MARCONES, MOREIRA PEREIRA",
          date: "2022-04-03",
          amount: 2829.96,
        },
        {
          sender: "NEIVALDO, MOREIRA PEREIRA",
          receiver: "EDVALDO, ALVES PEREIRA",
          date: "2022-04-03",
          amount: 960,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 107,
    name: "NILTON SERGIO",
    transactions: {
      aggregation: 7534.74,
      transactionAvg: 7534.74,
      transactions: [
        {
          sender: "NILTON SERGIO, VALLE FERNANDES",
          receiver: "VALDISON APARECIDO, NUNES RIBEIRO",
          date: "2022-04-27",
          amount: 7453.98,
        },
        {
          sender: "NILTON SERGIO, VALLE FERNANDES",
          receiver: "NILTON SERGIO, VALLE FERNANDES",
          date: "2022-04-27",
          amount: 80.76,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 108,
    name: "OLDINEI TEIXEIRA",
    transactions: {
      aggregation: 3110,
      transactionAvg: 3110,
      transactions: [
        {
          sender: "OLDINEI TEIXEIRA, CARNEIRO",
          receiver: "OLDINEI TEIXEIRA, CARNEIRO",
          date: "2022-09-28",
          amount: 3010,
        },
        {
          sender: "OLDINEI TEIXEIRA, CARNEIRO",
          receiver: "MARIA LUCIA, TEIXEIRA SILVA CARNEIRO",
          date: "2022-09-28",
          amount: 100,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 109,
    name: "OTAVIO",
    transactions: {
      aggregation: 6219,
      transactionAvg: 6219,
      transactions: [
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "OTAVIO, DA SILVA ALVES",
          date: "2022-05-02",
          amount: 3010,
        },
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "OTAVIO, DA SILVA ALVES",
          date: "2022-05-02",
          amount: 3209,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 110,
    name: "OTAVIO",
    transactions: {
      aggregation: 3127.34,
      transactionAvg: 1042.4466666666667,
      transactions: [
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "LEANDRO, MESSIAS MARTINS",
          date: "2022-06-21",
          amount: 208.81,
        },
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "ALISSON, VINICIUS DE AMORIM",
          date: "2022-06-21",
          amount: 725.71,
        },
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "OTAVIO, DA SILVA ALVES",
          date: "2022-06-21",
          amount: 1004.04,
        },
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "NILMA APARECIDA, DE SOUZA",
          date: "2022-06-21",
          amount: 1188.78,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 111,
    name: "OTAVIO",
    transactions: {
      aggregation: 6040.59,
      transactionAvg: 6040.59,
      transactions: [
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "PEDRO, FORNARI",
          date: "2023-01-20",
          amount: 3873.73,
        },
        {
          sender: "OTAVIO, DA SILVA ALVES",
          receiver: "REGINALDO SILVA, VAZ",
          date: "2023-01-20",
          amount: 2166.86,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 112,
    name: "PABLO JUAN",
    transactions: {
      aggregation: 3281,
      transactionAvg: 3281,
      transactions: [
        {
          sender: "PABLO JUAN, PASSOS",
          receiver: "PABLO, JUAN PASSOS",
          date: "2022-12-19",
          amount: 1570,
        },
        {
          sender: "PABLO JUAN, PASSOS",
          receiver: "CN CONSTRUTORA, E INCORPORADORA LTDA",
          date: "2022-12-19",
          amount: 1711,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 113,
    name: "RACHEL Y",
    transactions: {
      aggregation: 3117.66,
      transactionAvg: 1558.83,
      transactions: [
        {
          sender: "RACHEL Y, SANTANA ALVES",
          receiver: "DELMA MARIA, OLIVEIRA",
          date: "2022-05-16",
          amount: 1105.62,
        },
        {
          sender: "RACHEL Y, SANTANA ALVES",
          receiver: "JULIANO AUGUSTO, DE S OLIVEIRA",
          date: "2022-05-16",
          amount: 1006.02,
        },
        {
          sender: "RACHEL Y, SANTANA ALVES",
          receiver: "JOSE, RAFAEL",
          date: "2022-05-16",
          amount: 1006.02,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 114,
    name: "RAIMUNDO",
    transactions: {
      aggregation: 4312.91,
      transactionAvg: 2156.455,
      transactions: [
        {
          sender: "RAIMUNDO, BATISTA PINHEIRO",
          receiver: "RAIMUNDO BATISTA, PINHEIRO",
          date: "2022-10-06",
          amount: 3939.27,
        },
        {
          sender: "RAIMUNDO, BATISTA PINHEIRO",
          receiver: "FRANCISCA, FERNANDES COSTA",
          date: "2022-10-06",
          amount: 245.76,
        },
        {
          sender: "RAIMUNDO, BATISTA PINHEIRO",
          receiver: "MARIA DA CONCEICAO, FEITOSA PINHEIRO",
          date: "2022-10-06",
          amount: 127.88,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 115,
    name: "REBECA",
    transactions: {
      aggregation: 5165.71,
      transactionAvg: 5165.71,
      transactions: [
        {
          sender: "REBECA, QUEIROZ",
          receiver: "REBECA, QUEIROZ",
          date: "2022-08-23",
          amount: 4010,
        },
        {
          sender: "REBECA, QUEIROZ",
          receiver: "CARLA PATRICIA MUNOZ, LOPEZ",
          date: "2022-08-23",
          amount: 1155.71,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 116,
    name: "RENATO W",
    transactions: {
      aggregation: 3150.26,
      transactionAvg: 3150.26,
      transactions: [
        {
          sender: "RENATO W, DE LIMA",
          receiver: "REGINALDO CLENIO, DE LIMA",
          date: "2022-05-20",
          amount: 2026.81,
        },
        {
          sender: "RENATO W, DE LIMA",
          receiver: "JOSE, COELHO MAGALHAES",
          date: "2022-05-20",
          amount: 1123.45,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 117,
    name: "RICARDO",
    transactions: {
      aggregation: 3788.92,
      transactionAvg: 3788.92,
      transactions: [
        {
          sender: "RICARDO, BRANDHUBER",
          receiver: "RICARDO, BRANDHUBER",
          date: "2022-07-25",
          amount: 3600,
        },
        {
          sender: "RICARDO, BRANDHUBER",
          receiver: "RICARDO, BRANDHUBER",
          date: "2022-07-25",
          amount: 188.92,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 118,
    name: "ROGERIO",
    transactions: {
      aggregation: 3096.12,
      transactionAvg: 1548.06,
      transactions: [
        {
          sender: "ROGERIO, GONCALVES ALVES",
          receiver: "EDUARDO SANTOS, SILVA",
          date: "2022-03-22",
          amount: 1010,
        },
        {
          sender: "ROGERIO, GONCALVES ALVES",
          receiver: "JOEL, GERALDO DE SOUZA",
          date: "2022-03-22",
          amount: 1043.06,
        },
        {
          sender: "ROGERIO, GONCALVES ALVES",
          receiver: "DERCILIO, CORNELIO DE MORAIS",
          date: "2022-03-22",
          amount: 1043.06,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 119,
    name: "RONALD",
    transactions: {
      aggregation: 3120,
      transactionAvg: 3120,
      transactions: [
        {
          sender: "RONALD, GERHARDT VIEIRA SILVA",
          receiver: "RONALD GER HARDT, VIEIRA SILVA",
          date: "2022-10-08",
          amount: 2710,
        },
        {
          sender: "RONALD, GERHARDT VIEIRA SILVA",
          receiver: "RONALDO, DO NASCIMENTO SILVA",
          date: "2022-10-08",
          amount: 410,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 120,
    name: "RONAN RONILDO DOS SANTOS",
    transactions: {
      aggregation: 6828.11,
      transactionAvg: 6828.11,
      transactions: [
        {
          sender: "RONAN RONILDO DOS SANTOS, SILVA",
          receiver: "ODILIA MARIA, SILVA",
          date: "2022-10-31",
          amount: 228.96,
        },
        {
          sender: "RONAN RONILDO DOS SANTOS, SILVA",
          receiver: "ROBERTO PEDRO, DA SILVA",
          date: "2022-10-31",
          amount: 6599.15,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 121,
    name: "RONEIDE",
    transactions: {
      aggregation: 3820,
      transactionAvg: 3820,
      transactions: [
        {
          sender: "RONEIDE, GONCALVES DE LIMA SILVA",
          receiver: "LORENA FRANCIELLY, SILVA",
          date: "2023-01-16",
          amount: 3510,
        },
        {
          sender: "RONEIDE, GONCALVES DE LIMA SILVA",
          receiver: "LORENA FRANCIELLY, SILVA",
          date: "2023-01-16",
          amount: 310,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 122,
    name: "ROSSANA PATRICIA",
    transactions: {
      aggregation: 4015,
      transactionAvg: 4015,
      transactions: [
        {
          sender: "ROSSANA PATRICIA, SILVA RODRIGUES",
          receiver: "JOAO CARLOS, DE LIMA GONCALVES",
          date: "2022-09-20",
          amount: 2005,
        },
        {
          sender: "ROSSANA PATRICIA, SILVA RODRIGUES",
          receiver: "CARMEM, LUCIA RODRIGUES",
          date: "2022-09-20",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 123,
    name: "SARAH R",
    transactions: {
      aggregation: 4145,
      transactionAvg: 4145,
      transactions: [
        {
          sender: "SARAH R, DE OLIVEIRA",
          receiver: "FABIANO ARAUJO, SILVA",
          date: "2022-04-28",
          amount: 3610,
        },
        {
          sender: "SARAH R, DE OLIVEIRA",
          receiver: "ICI BERTAN ARTESANATO, ME",
          date: "2022-04-28",
          amount: 535,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 124,
    name: "SIDNEY",
    transactions: {
      aggregation: 4700.7,
      transactionAvg: 2350.35,
      transactions: [
        {
          sender: "SIDNEY, FARIA DA SILVA",
          receiver: "JOICE DA SILVA, FARIA",
          date: "2022-06-30",
          amount: 310,
        },
        {
          sender: "SIDNEY, FARIA DA SILVA",
          receiver: "IVANETE PEREIRA, NUNES",
          date: "2022-06-30",
          amount: 2705,
        },
        {
          sender: "SIDNEY, FARIA DA SILVA",
          receiver: "IVANETE PEREIRA, NUNES",
          date: "2022-06-30",
          amount: 1685.7,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 125,
    name: "SIMONE MARIA",
    transactions: {
      aggregation: 3190.44,
      transactionAvg: 3190.44,
      transactions: [
        {
          sender: "SIMONE MARIA, DOS SANTOS",
          receiver: "LUCELIA, SANTOS SOUZA",
          date: "2022-06-30",
          amount: 185.44,
        },
        {
          sender: "SIMONE MARIA, DOS SANTOS",
          receiver: "SIMONE MARIA, DOS SANTOS",
          date: "2022-06-30",
          amount: 3005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 126,
    name: "SOLANGE",
    transactions: {
      aggregation: 5220,
      transactionAvg: 5220,
      transactions: [
        {
          sender: "SOLANGE, FERREIRA",
          receiver: "SUMAIA, AIRAM FONSECA DE CARVALHO",
          date: "2022-07-06",
          amount: 2610,
        },
        {
          sender: "SOLANGE, FERREIRA",
          receiver: "SUMAIA, AIRAM FONSECA DE CARVALHO",
          date: "2022-07-06",
          amount: 2610,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 127,
    name: "SUZANA",
    transactions: {
      aggregation: 4020,
      transactionAvg: 4020,
      transactions: [
        {
          sender: "SUZANA, MARINHO GONTIJO DE BRITO",
          receiver: "AMANDA CAROLINE, MARINHO GONTIJO",
          date: "2023-01-24",
          amount: 3010,
        },
        {
          sender: "SUZANA, MARINHO GONTIJO DE BRITO",
          receiver: "ALESSANDRA, MIRELLE DE LIMA",
          date: "2023-01-24",
          amount: 1010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 128,
    name: "TALES DANIEL",
    transactions: {
      aggregation: 6120,
      transactionAvg: 6120,
      transactions: [
        {
          sender: "TALES DANIEL, NUNES GONCALVES",
          receiver: "MARIA, FLAVIANA PEREIRA COSTA DA SILVA",
          date: "2022-07-05",
          amount: 1550,
        },
        {
          sender: "TALES DANIEL, NUNES GONCALVES",
          receiver: "TALES DANIEL, NUNES GONCALVES",
          date: "2022-07-05",
          amount: 4570,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 129,
    name: "TANIA",
    transactions: {
      aggregation: 5902.36,
      transactionAvg: 5902.36,
      transactions: [
        {
          sender: "TANIA, DE PAULA",
          receiver: "BARBARA DE PAULA, OLIVEIRA",
          date: "2022-09-01",
          amount: 2951.18,
        },
        {
          sender: "TANIA, DE PAULA",
          receiver: "BARBARA DE PAULA, OLIVEIRA",
          date: "2022-09-01",
          amount: 2951.18,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 130,
    name: "TATIANE TALINE",
    transactions: {
      aggregation: 3388.05,
      transactionAvg: 1694.025,
      transactions: [
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "NASCENTE EMPREENDIMENTOS IMOBILIARIOS, LTDA",
          date: "2022-03-07",
          amount: 1581.72,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "EDUARDO LOPES, DOS SANTOS",
          date: "2022-03-07",
          amount: 801.33,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "TATIANE, TALINE SILVA ALVES",
          date: "2022-03-07",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 131,
    name: "TATIANE TALINE",
    transactions: {
      aggregation: 3564.8,
      transactionAvg: 3564.8,
      transactions: [
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "NASCENTE EMPREENDIMENTOS IMOBILIARIOS, LTDA",
          date: "2022-05-09",
          amount: 1559.8,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "TATIANE, TALINE SILVA ALVES",
          date: "2022-05-09",
          amount: 2005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 132,
    name: "TATIANE TALINE",
    transactions: {
      aggregation: 3675.49,
      transactionAvg: 1837.745,
      transactions: [
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "NASCENTE EMPREENDIMENTOS IMOBILIARIOS, LTDA",
          date: "2022-06-08",
          amount: 1641.03,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "ALTA ESTACAO, AGENCIA DE VIAGENS E BTURISMO LTDA",
          date: "2022-06-08",
          amount: 313.98,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "TATIANE, TALINE SILVA ALVES",
          date: "2022-06-08",
          amount: 1720.48,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 133,
    name: "TATIANE TALINE",
    transactions: {
      aggregation: 4601.52,
      transactionAvg: 2300.76,
      transactions: [
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "JSQUEIROZ, CONSTRUTORA LTDA EPP",
          date: "2022-08-29",
          amount: 2026.13,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "NASCENTE EMPREENDIMENTOS IMOBILIARIOS, LTDA",
          date: "2022-08-29",
          amount: 1570.39,
        },
        {
          sender: "TATIANE TALINE, SILVA ALVES",
          receiver: "TATIANE, TALINE SILVA ALVES",
          date: "2022-08-29",
          amount: 1005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 134,
    name: "TATIANE",
    transactions: {
      aggregation: 4712.28,
      transactionAvg: 4712.28,
      transactions: [
        {
          sender: "TATIANE, CANDIDA DE JESUS ARAUJO",
          receiver: "ANA CRISTINA, CANDIDA DE JESUS",
          date: "2022-09-18",
          amount: 107.28,
        },
        {
          sender: "TATIANE, CANDIDA DE JESUS ARAUJO",
          receiver: "FLAVIO W, DE ARAUJO",
          date: "2022-09-18",
          amount: 4605,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 135,
    name: "THAINARA",
    transactions: {
      aggregation: 3012.16,
      transactionAvg: 3012.16,
      transactions: [
        {
          sender: "THAINARA, SILVA MOTA",
          receiver: "ALBERTINO ANTONIO, DA SILVA",
          date: "2022-09-19",
          amount: 402.16,
        },
        {
          sender: "THAINARA, SILVA MOTA",
          receiver: "ALBERTINO ANTONIO, DA SILVA",
          date: "2022-09-19",
          amount: 2610,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 136,
    name: "THIAGO HENRIQUE",
    transactions: {
      aggregation: 4180,
      transactionAvg: 2090,
      transactions: [
        {
          sender: "THIAGO HENRIQUE, RIBEIRO",
          receiver: "EDVANI, JUVENCIO SANTOS",
          date: "2023-02-28",
          amount: 230,
        },
        {
          sender: "THIAGO HENRIQUE, RIBEIRO",
          receiver: "CINTIA, XAVIER RIBEIRO MELATO",
          date: "2023-02-28",
          amount: 3532.5,
        },
        {
          sender: "THIAGO HENRIQUE, RIBEIRO",
          receiver: "THIAGO HENRIQUE, RIBEIRO",
          date: "2023-02-28",
          amount: 417.5,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 137,
    name: "THIAGO",
    transactions: {
      aggregation: 5954.27,
      transactionAvg: 2977.135,
      transactions: [
        {
          sender: "THIAGO, DAVID LUCHINI",
          receiver: "MYRLA AMANOELE, DAVID LUCHINI LORDELO",
          date: "2023-02-09",
          amount: 390.58,
        },
        {
          sender: "THIAGO, DAVID LUCHINI",
          receiver: "THIAGO DAVID, LUCHINI",
          date: "2023-02-09",
          amount: 553.69,
        },
        {
          sender: "THIAGO, DAVID LUCHINI",
          receiver: "THIAGO DAVID, LUCHINI",
          date: "2023-02-09",
          amount: 5010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 138,
    name: "VALERIA MARIA",
    transactions: {
      aggregation: 7315,
      transactionAvg: 7315,
      transactions: [
        {
          sender: "VALERIA MARIA, DE SOUZA",
          receiver: "VALERIA MARIA, DE SOUZA",
          date: "2022-10-29",
          amount: 7205,
        },
        {
          sender: "VALERIA MARIA, DE SOUZA",
          receiver: "CLAUDIENE, MARIA DE SOUZA",
          date: "2022-10-29",
          amount: 110,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 139,
    name: "VALQUIRIA",
    transactions: {
      aggregation: 6165,
      transactionAvg: 6165,
      transactions: [
        {
          sender: "VALQUIRIA, ABRANTES BATISTA",
          receiver: "AMANDA, FERREIRA LOPES",
          date: "2022-05-10",
          amount: 160,
        },
        {
          sender: "VALQUIRIA, ABRANTES BATISTA",
          receiver: "LUCAS BATISTA, GUIMARAES",
          date: "2022-05-10",
          amount: 6005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 140,
    name: "VALQUIRIA",
    transactions: {
      aggregation: 6215,
      transactionAvg: 6215,
      transactions: [
        {
          sender: "VALQUIRIA, ABRANTES BATISTA",
          receiver: "AMANDA, FERREIRA LOPES",
          date: "2022-07-12",
          amount: 210,
        },
        {
          sender: "VALQUIRIA, ABRANTES BATISTA",
          receiver: "LUCAS BATISTA, GUIMARAES",
          date: "2022-07-12",
          amount: 6005,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 141,
    name: "VANDERLEI",
    transactions: {
      aggregation: 4005,
      transactionAvg: 4005,
      transactions: [
        {
          sender: "VANDERLEI, LOPES DE MACEDO",
          receiver: "TASSIO JUNIOR, DOS SANTOS",
          date: "2022-07-18",
          amount: 3600,
        },
        {
          sender: "VANDERLEI, LOPES DE MACEDO",
          receiver: "TASSIO JUNIOR, DOS SANTOS",
          date: "2022-07-18",
          amount: 405,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 142,
    name: "VANESSA",
    transactions: {
      aggregation: 3545.81,
      transactionAvg: 3545.81,
      transactions: [
        {
          sender: "VANESSA, RIBEIRO COUTINHO",
          receiver: "PAULA CRISTINA, PEREIRA DA SILVA",
          date: "2022-04-08",
          amount: 310,
        },
        {
          sender: "VANESSA, RIBEIRO COUTINHO",
          receiver: "VANESSA, RIBEIRO COUTINHO",
          date: "2022-04-08",
          amount: 3235.81,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 143,
    name: "VERALICIA",
    transactions: {
      aggregation: 3737.31,
      transactionAvg: 3737.31,
      transactions: [
        {
          sender: "VERALICIA, FERREIRA SILVA",
          receiver: "VILMAR, RIBEIRO DA FONSECA",
          date: "2022-07-08",
          amount: 2285,
        },
        {
          sender: "VERALICIA, FERREIRA SILVA",
          receiver: "DANILO, RIBEIRO DA FONSECA",
          date: "2022-07-08",
          amount: 1452.31,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 144,
    name: "VICTOR FERNANDO",
    transactions: {
      aggregation: 7136.210000000001,
      transactionAvg: 7136.210000000001,
      transactions: [
        {
          sender: "VICTOR FERNANDO, NUNES MELO",
          receiver: "COFER, FERRO E ACO",
          date: "2022-12-24",
          amount: 5742.81,
        },
        {
          sender: "VICTOR FERNANDO, NUNES MELO",
          receiver: "PREMOLAGO PREMOLDADOS, SERRALHERIA LTDA",
          date: "2022-12-24",
          amount: 1393.4,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 145,
    name: "VINICIUS",
    transactions: {
      aggregation: 3998.26,
      transactionAvg: 1999.13,
      transactions: [
        {
          sender: "VINICIUS, GUIMARAES DE DEUS LIMA",
          receiver: "GERALDO DE DEUS, LIMA",
          date: "2022-04-13",
          amount: 336.8,
        },
        {
          sender: "VINICIUS, GUIMARAES DE DEUS LIMA",
          receiver: "MARIA DO CARMO, ANDRADE",
          date: "2022-04-13",
          amount: 881.46,
        },
        {
          sender: "VINICIUS, GUIMARAES DE DEUS LIMA",
          receiver: "VINICIUS GUIMARAES, DE DEUS LIMA",
          date: "2022-04-13",
          amount: 2780,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 146,
    name: "WALLACE HENRIQUE",
    transactions: {
      aggregation: 3800,
      transactionAvg: 3800,
      transactions: [
        {
          sender: "WALLACE HENRIQUE, OLIVEIRA E SILVA",
          receiver: "WALLACE HENRIQUE, OLIVEIRA E SILVA",
          date: "2022-04-20",
          amount: 1800,
        },
        {
          sender: "WALLACE HENRIQUE, OLIVEIRA E SILVA",
          receiver: "WALLACE HENRIQUE, OLIVEIRA E SILVA",
          date: "2022-04-20",
          amount: 2000,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 147,
    name: "WELLINGTON",
    transactions: {
      aggregation: 7403.47,
      transactionAvg: 3701.735,
      transactions: [
        {
          sender: "WELLINGTON, RODRIGUES DA SILVA",
          receiver: "LOURENCO DAS, DORES DE FREITAS",
          date: "2022-06-05",
          amount: 1609.15,
        },
        {
          sender: "WELLINGTON, RODRIGUES DA SILVA",
          receiver: "JAIME RODRIGUES, DA FONSECA",
          date: "2022-06-05",
          amount: 1289.32,
        },
        {
          sender: "WELLINGTON, RODRIGUES DA SILVA",
          receiver: "WELLINGTON RODRIGUES, DA SILVA",
          date: "2022-06-05",
          amount: 4505,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 148,
    name: "WELLINGTON",
    transactions: {
      aggregation: 3469.9,
      transactionAvg: 3469.9,
      transactions: [
        {
          sender: "WELLINGTON, RODRIGUES DA SILVA",
          receiver: "SONYA, CORREA RIBEIRO DA SILVA",
          date: "2023-02-28",
          amount: 1888.19,
        },
        {
          sender: "WELLINGTON, RODRIGUES DA SILVA",
          receiver: "JOSE WILSON, CANDIDO MARQUES",
          date: "2023-02-28",
          amount: 1581.71,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 149,
    name: "WILDER",
    transactions: {
      aggregation: 9820,
      transactionAvg: 9820,
      transactions: [
        {
          sender: "WILDER, JOSE ALVES",
          receiver: "WILDER JOSE, ALVES",
          date: "2022-11-25",
          amount: 6010,
        },
        {
          sender: "WILDER, JOSE ALVES",
          receiver: "WILDER JOSE, ALVES",
          date: "2022-11-25",
          amount: 3810,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 150,
    name: "WILDER",
    transactions: {
      aggregation: 3175.8199999999997,
      transactionAvg: 3175.8199999999997,
      transactions: [
        {
          sender: "WILDER, PARANHOS SILVA MARTINS",
          receiver: "WILDER PARANHOS, SILVA MARTINS",
          date: "2023-01-19",
          amount: 1410,
        },
        {
          sender: "WILDER, PARANHOS SILVA MARTINS",
          receiver: "WILDER PARANHOS, SILVA MARTINS",
          date: "2023-01-19",
          amount: 1765.82,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 151,
    name: "WILLIAM",
    transactions: {
      aggregation: 7010,
      transactionAvg: 7010,
      transactions: [
        {
          sender: "WILLIAM, JESUS DOS SANTOS",
          receiver: "WILLIAM JESUS, DOS SANTOS",
          date: "2022-05-08",
          amount: 5505,
        },
        {
          sender: "WILLIAM, JESUS DOS SANTOS",
          receiver: "WILLIAM JESUS, DOS SANTOS",
          date: "2022-05-08",
          amount: 1505,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 152,
    name: "WILMA APARECIDA",
    transactions: {
      aggregation: 3015,
      transactionAvg: 3015,
      transactions: [
        {
          sender: "WILMA APARECIDA, ARAUJO",
          receiver: "MARCIO, RILDO RAMALHO MARQUES",
          date: "2022-11-26",
          amount: 2855,
        },
        {
          sender: "WILMA APARECIDA, ARAUJO",
          receiver: "WILMA APARECIDA, DE ARAUJO",
          date: "2022-11-26",
          amount: 160,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 153,
    name: "WLISSES",
    transactions: {
      aggregation: 4020,
      transactionAvg: 4020,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2022-06-15",
          amount: 2010,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2022-06-15",
          amount: 2010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 154,
    name: "WLISSES",
    transactions: {
      aggregation: 3879.64,
      transactionAvg: 3879.64,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2022-07-28",
          amount: 1939.82,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2022-07-28",
          amount: 1939.82,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 155,
    name: "WLISSES",
    transactions: {
      aggregation: 3468.8199999999997,
      transactionAvg: 3468.8199999999997,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2022-08-17",
          amount: 1958.82,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2022-08-17",
          amount: 1510,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 156,
    name: "WLISSES",
    transactions: {
      aggregation: 7020,
      transactionAvg: 7020,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2022-09-30",
          amount: 4010,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2022-09-30",
          amount: 3010,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 157,
    name: "WLISSES",
    transactions: {
      aggregation: 3889.6499999999996,
      transactionAvg: 3889.6499999999996,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2023-02-10",
          amount: 1953.58,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2023-02-10",
          amount: 1936.07,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 158,
    name: "WLISSES",
    transactions: {
      aggregation: 5047.08,
      transactionAvg: 5047.08,
      transactions: [
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "RERICA, POLIANA TAVEIRA GODOY",
          date: "2023-01-08",
          amount: 3104.78,
        },
        {
          sender: "WLISSES, MOREIRA DE SOUZA",
          receiver: "JOAO, FERREIRA DE SOUSA",
          date: "2023-01-08",
          amount: 1942.3,
        },
      ],
      recipients: {},
    },
  },
  {
    id: 159,
    name: "ZILDA MARIA",
    transactions: {
      aggregation: 3448.57,
      transactionAvg: 3448.57,
      transactions: [
        {
          sender: "ZILDA MARIA, RODRIGUES",
          receiver: "VERA L, MARICONI",
          date: "2022-09-13",
          amount: 248.57,
        },
        {
          sender: "ZILDA MARIA, RODRIGUES",
          receiver: "VERA L, MARICONI",
          date: "2022-09-13",
          amount: 3200,
        },
      ],
      recipients: {},
    },
  },
];
