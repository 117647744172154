import { Box, IconButton, Button, useTheme, Typography } from "@mui/material";
import { fileReader } from "../../data/FileReader";

import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import { useSignOut } from "react-auth-kit";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { formatDate } from "../../utils";
import MultipleSelectCheckmarks from "../../components/MultipleSelectCheckmarks/MultipleSelectCheckmarks";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Topbar = ({
  setCredentials,
  agency,
  setAgency,
  setRawTransactions,
  setAuthId,
  period,
  setPeriod,
  setLoader,
  credentials,
  auth,
  transactions,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const logOut = () => {
    signOut();
    navigate("/login");
    setCredentials({});
    setRawTransactions([]);
    setAuthId(null);
  };
  const updateDate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPeriod({ ...period, [name]: value });
  };

  return (
    <Box className="not-for-report">
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div>
          <Typography
            variant="h3"
            color={colors.grey[100]}
            fontWeight="bold"
            // sx={{ m: "20px 0 0 20px" }}
          >
            {auth().agency}
          </Typography>
        </div>
        <div>
          {/* ICONS */}
          <Box>
            {/* <Button
            size="small"
            variant="outlined"
            sx={{
              // backgroundColor: colors.greenAccent[700],
              color: colors.greenAccent[500],
              // fontSize: "14px",
              fontWeight: "bold",
              padding: "15px 20px",
              marginRight: 1,
              border: `thin solid${colors.greenAccent[500]}`,
            }}
            component="label"
          >
            <CloudUploadIcon style={{ marginRight: "10px" }} />
            Upload Transactions
            <input
              hidden
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0];
                setLoader(true);
                fileReader(
                  file,
                  setRawTransactions,
                  credentials,
                  auth,
                  setLoader
                );
              }}
            />
          </Button> */}
            <Typography
              variant="body"
              color={colors.greenAccent[500]}
              style={{ marginRight: 8 }}
            >
              {auth().email}
            </Typography>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
            <IconButton onClick={logOut}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </div>
      </Box>

      <Box display="flex" justifyContent="space-between" p={2}>
        {/* SEARCH BAR */}

        <Box
          display="flex"
          // backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
          {(transactions && (
            <Box display="flex" p={0}>
              <TextField
                id="dateFrom"
                label="Period From"
                name="from"
                type="date"
                onChange={updateDate}
                defaultValue={formatDate(period.from)}
                sx={{
                  width: 150,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="dateTo"
                label="Period to"
                name="to"
                type="date"
                onChange={updateDate}
                defaultValue={formatDate(period.to)}
                sx={{
                  width: 150,
                  marginLeft: 2,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <MultipleSelectCheckmarks agency={agency} setAgency={setAgency} />
            </Box>
          )) ||
            ""}
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
